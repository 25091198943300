import React from 'react'
import { error_404 } from './assets'
import { useTranslation } from 'react-i18next'

export default function NotFound() {
const {t} = useTranslation()
  return (
    <div className="error_404_content">
        <img className="error_404_image" src={error_404} alt="error_404"/>
        <h3 className="error_404_header">{t("Page not found")}</h3>
    </div>
  )
}
