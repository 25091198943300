import React,{useState, useEffect, useRef} from 'react'
import * as Yup from 'yup';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import { Input, Button } from 'antd'; // Replace with your actual component library
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Header1 from './../../common/Header1';
import style from './styles/footer.module.css'
import { SwalAlert } from './../alert/SwalAlert';
import { FaXTwitter,FaInstagram  } from "react-icons/fa6";
import { LuLinkedin } from "react-icons/lu";
import { FiFacebook } from "react-icons/fi";
import { TbBrandSnapchat, TbBrandTiktok } from "react-icons/tb";
import { MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { sustainabilty_image } from '../../assets';

export default function Footer() {
const {t,i18n} = useTranslation()
const navigate = useNavigate()
const location = useLocation();
const formRef = useRef()
const[matches,setMatches] = useState(window.matchMedia("(min-width: 1100px)").matches)
useEffect(() => {const handler = (e) => setMatches( e.matches ); window.matchMedia("(min-width: 1100px)").addListener(handler);},[])
const validationSchema = Yup.object().shape({
  email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
});

const initialValues = {
  email: '',
};

const handleSubmit = (values, {resetForm}) => {
  // Handle registration logic here
  console.log('Registration form submitted with values:', values);
  SwalAlert({ text: t("You have registered successfully, you will recieve latest news"), status: true })
  resetForm()
};
const activeClassName = (pathname) => location.pathname === pathname ? style.Route_active : style.Route;

useEffect(()=>{
  formRef.current.resetForm();
},[location.pathname ,i18n.language])

  return (
    <div className={style.Footer}>
      <div className={style.Content_1}>

        <div className={style.sec_2}>
          <div className={style.footer_header_content}><span >{t("Register in")}</span><span style={{color:"rgba(136, 5, 13, 1)", fontWeight:"bolder", fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}}> {t("Newsletter")} </span><br></br><span className={style.small_heading}>{t("to recieve all news of ")}</span><br></br><span className={style.small_heading}>{t("handcarfts in Emirates")}</span></div>
          
          <Formik innerRef={formRef} initialValues={initialValues}  validationSchema={validationSchema} onSubmit={handleSubmit}>
            <Form className={style.Form}>
              <Field
                className={style.input}
                name="email"
                placeholder={t('Email Address')}
                style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl', fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light" }}
                as={Input}
              />
              <ErrorMessage name="email" component="div" className={style.error} />
              <Button htmlType="submit" id={style.Register} style={{fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}}>
                {t('Register')}
              </Button>
            </Form>
        </Formik>
        </div>

        <div className={style.sec_1}>
              <Header1 classname={style.h1} text={"Join Sona3 Society"}/>
              <div className={style.Social_Icons}>
                <NavLink ><FiFacebook onClick={()=>{window.open(`https://www.facebook.com/sona3app`, '_blank')}}  className={style.img_link} alt="1"/></NavLink>
                <NavLink ><FaXTwitter onClick={()=>{window.open(`https://twitter.com/Sona3app`, '_blank')}}  className={style.img_link} alt="2"/></NavLink>
                <NavLink ><FaInstagram onClick={()=>{window.open(`https://www.instagram.com/sona3app/`, '_blank')}}  className={style.img_link} alt="3"/></NavLink>
                <NavLink ><TbBrandSnapchat onClick={()=>{window.open(`https://www.snapchat.com/add/sona3app`, '_blank')}}  className={style.img_link} alt="4"/></NavLink>
                <NavLink ><TbBrandTiktok onClick={()=>{window.open(`https://www.tiktok.com/@sona3app`, '_blank')}}  className={style.img_link} alt="5" /></NavLink>
                <NavLink ><LuLinkedin onClick={()=>{window.open(`https://www.linkedin.com/company/sona3app/`, '_blank')}}  className={style.img_link} alt="6" /></NavLink>
              </div>
              <div className={style.contact_content}>
                <div className={style.contact_li}>
                  {i18n.language ==="en" ? <> <MailOutlined className={style.contact_li_icon}/><span style={{fontFamily: "sans-serif"}}><a href="mailto:info@sona3.ae" style={{color: "inherit", textDecoration: "none"}}>info@sona3.ae</a></span></> :
                   <> <span style={{fontFamily: "sans-serif"}}><a href="mailto:info@sona3.ae" style={{color: "inherit", textDecoration: "none"}}>info@sona3.ae</a></span><MailOutlined className={style.contact_li_icon}/></> 
                  }
                </div>
                <div className={style.contact_li}>
                {i18n.language ==="en" ? <> 
                   <PhoneOutlined className={style.contact_li_icon}/> <a href="tel:+971600505006" style={{color: "inherit", textDecoration: "none", fontFamily:"sans-serif"}}>+971 60 050 5006</a> </>:
                  <> <a href="tel:+971600505006" style={{color: "inherit", textDecoration: "none", fontFamily:"sans-serif"}}>+971 60 050 5006</a><PhoneOutlined className={style.contact_li_icon}/>  </>
                }
                </div>
              </div>
              <img onClick={()=>{navigate('/sustainability')}} src={sustainabilty_image} className={style.sustainabilty_image} alt="sustainabilty_image"/>
        </div>
     
      </div>
      <div className={style.Content_2}>
        <div className={style.Content_2_Routes}>
          { matches && 
          <div className={style.Routes}>
              <NavLink to="/" className={activeClassName("/")}>{t("Home")}</NavLink>
              <NavLink to="/about" className={activeClassName("/about")}>{t("About")}</NavLink>
              <NavLink to="/be%20a%20partner" className={activeClassName("/be%20a%20partner")} >{t("Be a partner")}</NavLink>
              {/* <NavLink to="/services" className={activeClassName("/blog")}>{t("Services")}</NavLink> */}
              <NavLink to="/sustainability" className={activeClassName("/sustainability")}>{t("Sustainability")}</NavLink>
              <NavLink to={i18n?.language === 'en' ?"https://sona3.blog/" :"https://sona3.blog/ar"} className={activeClassName("/blog")}>{t("Blog")}</NavLink>
              <NavLink to="/support" className={activeClassName("/support")}>{t("Support")}</NavLink>
          </div>
          }
          <div className={style.addition_routes}>
              <NavLink to="/privacy-policy" className={activeClassName("/privacy-policy")}>{t("Privacy & Policy")}</NavLink>
              <NavLink to="/terms-conditions" className={activeClassName("/terms-conditions")}>{t("Terms & Conditions")}</NavLink>
              <NavLink to="/faqs" className={activeClassName("/faqs")}>{t("Common Questions")}</NavLink>
              <NavLink to="/sell-with-us" className={activeClassName("/sell-with-us")}>{t("Sell With Us")}</NavLink>
          </div>
        </div>
        <div className={style.Rights}>{t("Sona3 All CopyRights Reserved ©")}</div>
      </div>
    </div>
  )
}
