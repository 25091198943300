import { useTranslation } from 'react-i18next';
import { b1, b2, b3, b4, b5, b6, b7, b8, b9, b10, b11, b12, b13, b14, b15, bag, cs, car, pay, hour, partner_1, partner_2, partner_3, partner_4, partner_5, partner_6, blog_1, pricing_1, pricing_2, pricing_3, pricing_4, pricing_5, pricing_6, pricing_7, pricing_8, pricing_9, partner_7 } from '../assets';


export const advantages = [
    {
      nameAr:"فرصة تمكين أفراد أسرتك",
      nameEn:"The opportunity to empower your family members",
      icon:pricing_1
    },
    {
      nameAr:"تسليط الضوء علي اسمك كعلامة تجاريه",
      nameEn:"Highlight your name as a brand",
      icon:pricing_2
    },
    {
      nameAr:"تحويل الهوايه إلى عمل احترافي",
      nameEn:"Turning a hobby into a professional business",
      icon:pricing_3
    },
    {
      nameAr:"العمل من المنزل",
      nameEn:"Work from home",
      icon:pricing_4
    },
    {
      nameAr:"الوصول إلى العملاء",
      nameEn:"Reaching customers",
      icon:pricing_5
    },
    {
      nameAr:"الخدمات التسويقيه والتجاريه",
      nameEn:"Marketing and commercial services",
      icon:pricing_6
    },
    {
      nameAr:"متابعة أداء المنتجات وفهم احتياجات السوق",
      nameEn:"Monitor product performance and understand market needs",
      icon:pricing_7
    },
    {
      nameAr:"تقليل التكاليف الإدراية",
      nameEn:"Reducing administrative costs",
      icon:pricing_8
    },
    {
      nameAr:"الاستفاده من التقارير والتحويلات",
      nameEn:"Take advantage of reports and conversions",
      icon:pricing_9
    },
]

  export const Packages = {
    services:[
      {
      name:"lifetime",
       p:"Level up with more power and enhanced features",
        price_year:10000,
        commisions:"0%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of services displayed (5)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Service registration fees"},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery (Contact us)", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Services photography (Contact us)"},
          {status:true,name:"Show service discounts", plus:true},
          {status:true,name:"Special offers (coupons)", plus:true},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
    {
      name:"basic",
       p:"Level up with more power and enhanced features",
        price_month:978,
        price_year:9780,
        commisions:"0%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of services displayed (1)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Service registration fees", plus:true},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery (Contact us)", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Services photography (Contact us)"},
          {status:true,name:"Show service discounts", plus:true},
          {status:true,name:"Special offers (coupons)", plus:true},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
    {
      name:"pro",
       p:"Level up with more power and enhanced features",
        price_month:1347,
        price_year:13470,
        commisions:"0%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of services displayed (7)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Service registration fees", plus:true},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery (Contact us)", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Services photography (Contact us)"},
          {status:true,name:"Show service discounts", plus:true},
          {status:true,name:"Special offers (coupons)", plus:true},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
    {
      name:"advanced",
       p:"Level up with more power and enhanced features",
        price_month:1499,
        price_year:14990,
        commisions:"0%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of services displayed (unlimited)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Service registration fees", plus:true},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery (Contact us)", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Services photography (Contact us)"},
          {status:true,name:"Show service discounts", plus:true},
          {status:true,name:"Special offers (coupons)", plus:true},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
      
  ],
  products:[
    {
      name:"lifetime",
       p:"Level up with more power and enhanced features",
        price_year:6000,
        commisions:"10%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of products displayed (5)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Product registration fees"},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Product photography ( 3 photos/prodcut )"},
          {status:true,name:"Show service discounts"},
          {status:true,name:"Special offers (coupons)"},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
          {status:true,name:"Manage shop throw ios, android", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
    {
      name:"basic",
       p:"Level up with more power and enhanced features",
        price_month:478,
        price_year:4780,
        commisions:"10%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of products displayed (1)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Product registration fees",plus:true},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Product photography ( 3 photos/prodcut )"},
          {status:true,name:"Show service discounts"},
          {status:true,name:"Special offers (coupons)"},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
          {status:true,name:"Manage shop throw ios, android", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
    {
      name:"pro",
       p:"Level up with more power and enhanced features",
        price_month:794,
        price_year:7940,
        commisions:"10%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of products displayed (7)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Product registration fees"},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Product photography ( 3 photos/prodcut )"},
          {status:true,name:"Show service discounts"},
          {status:true,name:"Special offers (coupons)"},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
          {status:true,name:"Manage shop throw ios, android", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
    {
      name:"advanced",
       p:"Level up with more power and enhanced features",
        price_month:936,
        price_year:9360,
        commisions:"10%",
        fixedPayment:"100AED",
        features:[
          {status:true,name:"Number of products displayed (unlimited)"},
          {status:true,name:"Number of requests (unlimited)"},
          {status:true,name:"Product registration fees"},
          {status:true,name:"Sustainability (Terms and conditions apply)"},
          {status:true,name:"Dedicated account manager", plus:true},
          {status:true,name:"Shipping and delivery", plus:true},
          {status:true,name:"24/7", plus:true},
        ],
        marketing:[
          {status:true,name:"Product photography ( 21 images maximum )"},
          {status:true,name:"Show service discounts"},
          {status:true,name:"Special offers (coupons)"},
        ],
        advanced:[
          {status:true,name:"SEO Optimization"},
          {status:true,name:"Social media publications" , plus:true},
          {status:true,name:"Show shop on application homepage", plus:true},
          {status:true,name:"Show shop on website homepage", plus:true},
          {status:true,name:"Manage shop throw ios, android", plus:true},
        ],
        payments:[
          {status:true,name:"Reports"},
          {status:true,name:"Payment with visa, mastercard"},
          {status:true,name:"Payment with ApplePay"},
          {status:true,name:"Merchant money settlement (monthly)"},
        ],
       },
  ],


}


  export const common_customer = [
    { key: '1', header: 'How can I change my registered email address?', content: 'By changing your profile settings' },
    { key: '2', header: 'How can I change my profile picture?', content: 'By changing your profile settings' },
    { key: '3', header: 'How can I know the status of the order?', content: 'Through the My Orders page, where the order details appear' },
    { key: '4', header: 'How can I verify my Makers account?', content: 'Via text message on the phone or email' },
  ];
  
  export const delivery_customer = [
    { key: '5', header: 'Can I select or schedule a pickup time?', content: 'yes' },
    { key: '6', header: 'What happens if my order is delayed?', content: 'If your order is significantly delayed beyond the estimated delivery time, please contact us through the communication channels on the website or application If our delivery of the product is delayed for reasons beyond our control, we will contact you as soon as possible to inform you of this, and we will take steps to reduce the consequences of delay in delivery.' },
    { key: '7', header: 'Can I register more than one address in my account?', content: 'Yes, it is possible' },
    { key: '8', header: 'How do I know when my order has been shipped?', content: "Through the user's profile, there is a tracking shipment button" },
    { key: '9', header: 'How long until my items arrive? How will shipping be done?', content: "Delivery date: This information will be displayed to you on the app or website. Once the order is ready for delivery, our delivery service will collect the order from the seller and deliver it to you within 24 hours." },
  ];
  
  export const payments_customer = [
    { key: '10', header: 'Do you add tax rates to your sales?', content: 'yes' },
    { key: '11', header: 'What payment methods are available at Sona3?', content: 'It is done entirely online through the payment options available on the SONA3 website and application, or through any payment method provided by SONA3 from time to time, for example: Credit card or debit card Your electronic wallet Cash on delivery (amount not exceeding AED 2,500)' },
  ];
  
  export const returns_customer = [
    { key: '12', header: 'How do I cancel my purchase?', content: "The customer's order can be canceled and the amount paid can be refunded if the seller does not accept the order, otherwise if the seller accepts the order, the purchase cannot be cancelled.If the seller cancels the order." },
    { key: '13', header: 'I would like to return or exchange the item?', content: 'The return and exchange policy is clarified in the list of terms of use for the manufacturers in the section on refunds and returned goods.' },
    { key: '14', header: 'How long does it take to receive a refund for my order?', content: 'Customers who qualify for the cancellation policy Under the terms of use and sale, the refund process takes up to 14 to 21 business days.' },
  ];
  
  export const support_customer = [
    { key: '15', header: 'I ordered a product, but I had the wrong shipping address. What do I do?', content: 'Please contact our customer service team' },
    { key: '16', header: 'I have some complaints about the product or my experience, how can I submit them?', content: 'Please contact our customer service team' },
    { key: '17', header: 'What should I do if there is an error with the product or ordering process?', content: 'Upon receipt of your order, if you discover that there are problems with the product or during the order process, please contact us through the communication channels on the website or application.' },
    { key: '18', header: "Makers' commitment to the environment?", content: 'To know all the details about our commitment to the environment, please visit our sustainability page' },
  ];




  export const common_seller = [
    { key: '19', header: 'How do I set up a store?', content: 'Register as a maker, then enter the required data accurately and you will be subscribed in no time' },
    { key: '20', header: "How can I change my store's logo image?", content: "By entering the store settings and setting its image" },
    { key: '21', header: "What should I include in my store policies?", content: "These policies shall include, but are not limited to: the exchange and return policy, and the sales policies. All store policies must comply with the usage and privacy policies set forth on ", link:"https://sona3.ae/"},
    { key: '22', header: "How can I add ads to my store on the Makers app?", content: "It can be added from within your store page" },
  ];
  
  export const delivery_seller = [
    { key: '23', header: 'What products and goods are prohibited from being sold by sona3?', content: 'You should review the Prohibited Items and Goods section of the Terms of Sale' },
    { key: '24', header: 'Is there a limit to the amount of products I can sell?', content: 'Yes, but there are paid subscription plans that increase the number of products you want to sell' },
    { key: '25', header: 'Is there a price limit for the product?', content: "You can price your products above or below the market price as long as it is competitive. All other products determine your prices in the market, which helps you stay competitive if your industry is crowded. With the rise of online shopping, it's now easier to compare prices before purchasing, which 96% of people do." },
    { key: '26', header: 'Can I specify or schedule a delivery time?', content: "It is determined according to each seller" },
    { key: '27', header: 'How can I add offers or discounts to the product?', content: "You can add it after you finish adding the product" },
    { key: '28', header: 'What is the sustainability label on products?', content: "As a craftsman in our store, you will receive a sustainability label that highlights your commitment to sustainable production. This label is not just a symbol, it is a testament to your efforts in using environmentally friendly materials and production techniques that conserve natural resources." },
    { key: '29', header: 'How can I get the sustainability label on products?', content: "You should review the terms for a sustainable product when listing a product" },
  ];
  
  export const payments_seller = [
    { key: '30', header: 'How can I get my money?', 
      content_en:<>
      <p>Payments to sellers will be settled and processed and transferred to their bank account on a monthly basis, taking into account the passage of 30 days from the last day of the settlement period: </p>
      <ol className='en_ol' dir='ltr'>
      <li>Settlement period: from the 1st to the last day of the month. </li>
      <li>Example: Payments for sales made in the period from July 1 to July 31 will be transferred on the first of September.</li>
      </ol>
      </>,
      content_ar:<>
      <p>سيتم  تسوية ومعالجة المدفوعات للتجار وتحويلها الي حسابهم البنكي شهريا لمراعاة مرور 30 يوم من اخر يوم بفترة التسوية :</p>
      <ol className='ar_ol' dir='rtl'>
        <li>فترة التسوية:  من تاريخ 1 الي أخر يوم بالشهر</li>
        <li>مثال: سيتم تحويل المدفوعات للمبيعات التي تمت في الفترة من 1 يوليو إلى 31 يوليو في الأول من سبتمبر.</li>
      </ol>
      </>,
      content:  'Payments to sellers will be settled and processed and transferred to their bank account on a monthly basis, taking into account the passage of 30 days from the last day of the settlement period: Settlement period: from the 1st to the last day of the month.  Example: Payments for sales made in the period from July 1 to July 31 will be transferred on the first of September.' },
    { key: '31', header: 'What payment methods are available at Sona3?', content: 'It is done entirely online through the payment options available on the SONA3 website and application, or through any payment method provided by SONA3 from time to time, for example: Credit card or debit card Your electronic wallet Cash on delivery (amount not exceeding AED 2,500)' },
  ];
  
  export const returns_seller = [
    { key: '32', header: 'How do I cancel my purchase?', content: "The customer's order can be canceled and the amount paid can be refunded if the seller does not accept the order, otherwise if the seller accepts the order, the purchase cannot be cancelled.If the seller cancels the order." },
    { key: '33', header: 'I would like to return or exchange the item?', content: 'The return and exchange policy is clarified in the list of terms of use for the manufacturers in the section on refunds and returned goods.' },
    { key: '34', header: 'How long does it take to receive a refund for my order?', content: 'Customers who qualify for the cancellation policy Under the terms of use and sale, the refund process takes up to 14 to 21 business days.' },
  ];
  
  export const support_seller = [
    { key: '35', header: "Makers' commitment to the environment?", content: 'Please contact our customer service team' },
  ];

  export const all_seller = [
    { key: '36', header: 'How do I set up a store?', content: 'Register as a maker, then enter the required data accurately and you will be subscribed in no time' },
    { key: '37', header: "How can I change my store's logo image?", content: "By entering the store settings and setting its image" },
    { key: '38', header: "What should I include in my store policies?", content: "These policies shall include, but are not limited to: the exchange and return policy, and the sales policies. All store policies must comply with the usage and privacy policies set forth on ", link:"https://sona3.ae/"},
    { key: '39', header: "How can I add ads to my store on the Makers app?", content: "It can be added from within your store page" },
  
    { key: '40', header: 'What products and goods are prohibited from being sold by sona3?', content: 'You should review the Prohibited Items and Goods section of the Terms of Sale' },
    { key: '41', header: 'Is there a limit to the amount of products I can sell?', content: 'Yes, but there are paid subscription plans that increase the number of products you want to sell' },
    { key: '42', header: 'Is there a price limit for the product?', content: "You can price your products above or below the market price as long as it is competitive. All other products determine your prices in the market, which helps you stay competitive if your industry is crowded. With the rise of online shopping, it's now easier to compare prices before purchasing, which 96% of people do." },
    { key: '43', header: 'Can I specify or schedule a delivery time?', content: "It is determined according to each seller" },
    { key: '44', header: 'How can I add offers or discounts to the product?', content: "You can add it after you finish adding the product" },
    { key: '45', header: 'What is the sustainability label on products?', content: "As a craftsman in our store, you will receive a sustainability label that highlights your commitment to sustainable production. This label is not just a symbol, it is a testament to your efforts in using environmentally friendly materials and production techniques that conserve natural resources." },
    { key: '46', header: 'How can I get the sustainability label on products?', content: "You should review the terms for a sustainable product when listing a product" },
  
    { key: '47', header: 'How can I get my money?',
      content_en:<>
      <p>Payments to sellers will be settled and processed and transferred to their bank account on a monthly basis, taking into account the passage of 30 days from the last day of the settlement period: </p>
      <ol className='en_ol' dir='ltr'>
      <li>Settlement period: from the 1st to the last day of the month. </li>
      <li>Example: Payments for sales made in the period from July 1 to July 31 will be transferred on the first of September.</li>
      </ol>
      </>,
      content_ar:<>
      <p>سيتم  تسوية ومعالجة المدفوعات للتجار وتحويلها الي حسابهم البنكي شهريا لمراعاة مرور 30 يوم من اخر يوم بفترة التسوية :</p>
      <ol className='ar_ol' dir='rtl'>
        <li>فترة التسوية:  من تاريخ 1 الي أخر يوم بالشهر</li>
        <li>مثال: سيتم تحويل المدفوعات للمبيعات التي تمت في الفترة من 1 يوليو إلى 31 يوليو في الأول من سبتمبر.</li>
      </ol>
      </>,
      
      content: 'Payments to sellers will be settled and processed and transferred to their bank account on a monthly basis, taking into account the passage of 30 days from the last day of the settlement period: Settlement period: from the 1st to the last day of the month. /n Example: Payments for sales made in the period from July 1 to July 31 will be transferred on the first of September.' },
    { key: '48', header: 'What payment methods are available at Sona3?', content: 'It is done entirely online through the payment options available on the SONA3 website and application, or through any payment method provided by SONA3 from time to time, for example: Credit card or debit card Your electronic wallet Cash on delivery (amount not exceeding AED 2,500)' },
  
    { key: '49', header: 'How do I cancel my purchase?', content: "The customer's order can be canceled and the amount paid can be refunded if the seller does not accept the order, otherwise if the seller accepts the order, the purchase cannot be cancelled.If the seller cancels the order." },
    { key: '50', header: 'I would like to return or exchange the item?', content: 'The return and exchange policy is clarified in the list of terms of use for the manufacturers in the section on refunds and returned goods.' },
    { key: '51', header: 'How long does it take to receive a refund for my order?', content: 'Customers who qualify for the cancellation policy Under the terms of use and sale, the refund process takes up to 14 to 21 business days.' },
  ];

  export const all_customer = [
    { key: '52', header: 'How can I change my registered email address?', content: 'By changing your profile settings' },
    { key: '53', header: 'How can I change my profile picture?', content: 'By changing your profile settings' },
    { key: '54', header: 'How can I know the status of the order?', content: 'Through the My Orders page, where the order details appear' },
    { key: '55', header: 'How can I verify my Makers account?', content: 'Via text message on the phone or email' },
    { key: '56', header: 'Can I select or schedule a pickup time?', content: 'yes' },
    { key: '57', header: 'What happens if my order is delayed?', content: 'If your order is significantly delayed beyond the estimated delivery time, please contact us through the communication channels on the website or application If our delivery of the product is delayed for reasons beyond our control, we will contact you as soon as possible to inform you of this, and we will take steps to reduce the consequences of delay in delivery.' },
    { key: '58', header: 'Can I register more than one address in my account?', content: 'Yes, it is possible' },
    { key: '59', header: 'How do I know when my order has been shipped?', content: "Through the user's profile, there is a tracking shipment button" },
    { key: '60', header: 'How long until my items arrive? How will shipping be done?', content: "Delivery date: This information will be displayed to you on the app or website. Once the order is ready for delivery, our delivery service will collect the order from the seller and deliver it to you within 24 hours." },
    { key: '61', header: 'Do you add tax rates to your sales?', content: 'yes' },
    { key: '62', header: 'What payment methods are available at Sona3?', content: 'It is done entirely online through the payment options available on the SONA3 website and application, or through any payment method provided by SONA3 from time to time, for example: Credit card or debit card Your electronic wallet Cash on delivery (amount not exceeding AED 2,500)' },
    { key: '63', header: 'How do I cancel my purchase?', content: "The customer's order can be canceled and the amount paid can be refunded if the seller does not accept the order, otherwise if the seller accepts the order, the purchase cannot be cancelled.If the seller cancels the order." },
    { key: '64', header: 'I would like to return or exchange the item?', content: 'The return and exchange policy is clarified in the list of terms of use for the manufacturers in the section on refunds and returned goods.' },
    { key: '65', header: 'How long does it take to receive a refund for my order?', content: 'Customers who qualify for the cancellation policy Under the terms of use and sale, the refund process takes up to 14 to 21 business days.' },
    { key: '66', header: 'I ordered a product, but I had the wrong shipping address. What do I do?', content: 'Please contact our customer service team' },
    { key: '67', header: 'I have some complaints about the product or my experience, how can I submit them?', content: 'Please contact our customer service team' },
    { key: '68', header: 'What should I do if there is an error with the product or ordering process?', content: 'Upon receipt of your order, if you discover that there are problems with the product or during the order process, please contact us through the communication channels on the website or application.' },
    { key: '69', header: "Makers' commitment to the environment?", content: 'To know all the details about our commitment to the environment, please visit our sustainability page' },
];











  
  export const cards = [
    {
        _id:1,
        header: 'Arrangement pottery elements workshop',
        descriptionEn: 'Join us for a creative pottery workshop where you will learn to arrange various pottery elements.',
        descriptionAr: 'انضم إلينا في ورشة الفخار الإبداعية حيث ستتعلم ترتيب عناصر الفخار المختلفة.',
        image:b1
    },
    {
        _id:2,
        header: 'Beautiful handmade ceramics pottery concept',
        descriptionEn: 'Discover the beauty of handmade ceramics with our unique pottery concepts.',
        descriptionAr: 'اكتشف جمال السيراميك اليدوي مع مفاهيم الفخار الفريدة لدينا.',
        image:b2

    },
    {
        _id:3,
        header: 'Carpenter working on a piece of wood - High view',
        descriptionEn: 'Experience the craftsmanship of a skilled carpenter working on a wood piece from a high angle view.',
        descriptionAr: 'شاهد حرفية نجار ماهر يعمل على قطعة خشب من زاوية عالية.',
        image:b3

    },
    {
        _id:4,
        header: 'Caucasian woman sewing homemade textile pattern indoors - Generated by AI',
        descriptionEn: 'A Caucasian woman sewing a homemade textile pattern indoors, depicted with AI-generated imagery.',
        descriptionAr: 'امرأة قوقازية تخيط نمطًا من القماش المنزلي في الداخل، موضحة بصور تم إنشاؤها بواسطة الذكاء الاصطناعي.',
        image:b4

    },
    {
        _id:5,
        header: 'Close-up female African American hands making clay pottery',
        descriptionEn: 'A close-up view of African American female hands skillfully crafting clay pottery.',
        descriptionAr: 'عرض عن قرب ليدين امرأة أفريقية أمريكية تصنع الفخار الطيني بمهارة.',
        image:b5

    },
    {
        _id:6,
        header: 'Colorful needlework embroidery flowers as background',
        descriptionEn: 'Bright and colorful needlework embroidery flowers create a vibrant background.',
        descriptionAr: 'الأزهار المطرزة بالأشغال اليدوية الملونة تشكل خلفية حيوية.',
        image:b6

    },
    {
        _id:7,
        header: 'From various angles, traditional bamboo bag webbing is decorated with Bohemian aesthetic',
        descriptionEn: 'Traditional bamboo bag weaving shown from various angles, adorned with a Bohemian aesthetic.',
        descriptionAr: 'نسج حقائب الخيزران التقليدية من زوايا مختلفة، مزينة بلمسات بوهيمية.',

        image:b7

    },
    {
        _id:8,
        header: 'Giving final touches to artwork',
        descriptionEn: 'An artist giving the final touches to their artwork, ensuring every detail is perfect.',
        descriptionAr: 'فنان يضع اللمسات الأخيرة على عمله الفني، ليضمن أن كل تفصيل مثالي.',
        image:b8

    },
    {
        _id:9,
        header: 'Person using macramé technique',
        descriptionEn: 'A person skillfully creating intricate designs using the macramé technique.',
        descriptionAr: 'شخص يستخدم تقنية المكرمية بإبداع لصنع تصاميم متقنة.',
        image:b9

    },
    {
        _id:10,
        header: 'Punch needle embroidery pillow DIY - Closeup skilled hands crafting cozy woolen masterpiece',
        descriptionEn: 'A close-up of skilled hands crafting a cozy woolen pillow using punch needle embroidery.',
        descriptionAr: 'عرض عن قرب لأيدٍ ماهرة تصنع وسادة صوفية مريحة باستخدام تطريز الإبرة.',
        image:b10

    },
    {
        _id:11,
        header: 'Top view hands knitting',
        descriptionEn: 'A top view of hands diligently knitting a beautiful piece.',
        descriptionAr: 'عرض من الأعلى لأيدٍ تحيك بجد قطعة جميلة.',
        image:b11

    },
    {
        _id:12,
        header: 'Turkish rug tapestry colorful symbol - Indigenous textile industry, generated by AI',
        descriptionEn: 'A colorful Turkish rug tapestry symbolizing the rich indigenous textile industry, created by AI.',
        descriptionAr: 'سجادة تركية ملونة ترمز إلى الصناعة النسيجية المحلية الغنية، تم إنشاؤها بواسطة الذكاء الاصطناعي.',
        image:b12

    },
    {
        _id:13,
        header: 'Unfinished jug',
        descriptionEn: 'An unfinished jug in the process of being crafted, showcasing the art of pottery making.',
        descriptionAr: 'إبريق غير مكتمل في طور الصنع، يعرض فن صناعة الفخار.',
        image:b13

    },
    {
        _id:14,
        header: 'Bag with rope it',
        descriptionEn: 'A stylish bag with a rope design, perfect for everyday use.',
        descriptionAr: 'حقيبة أنيقة بتصميم حبل، مثالية للاستخدام اليومي.',
        image:b14

    },
    {
        _id:15,
        header: 'Young adult organizing their knitting products',
        descriptionEn: 'A young adult meticulously organizing their hand-knitted products.',
        descriptionAr: 'شاب ينظم بدقة منتجاته المحبوكة يدويًا.',
        image:b15

    }
];

  


export default function Data() {
  const {t} = useTranslation()
  const sellerData = [
    {
      header: 'Extensive marketing',
      p:'We market your products effectively to reach a wider audience using advanced strategies to attract potential customers. We also care about providing attractive and creative content that highlights the beauty and uniqueness of our products.',
      img:bag,
      style:"rtl",
      mirror:"scaleX(1)",
      style_text:'start',
      color:"rgba(136, 5, 13, 1)"
    },
    {
      header: 'Various payment options',
      p: 'We are keen to provide diverse and secure payment options to our customers. We also offer innovative and reliable payment interfaces that include options such as payment via credit cards, direct debit, and bank transfers.'
      ,img:pay,
      style:"ltr",
      style_align:"right",
      mirror:"scaleX(-1)",
      style_text:'end',
      color:"rgba(10, 128, 106, 1)"
      
    },
    {
      header: '24/7 technical support',
      p: 'We strive to provide a comfortable and enjoyable shopping experience for our customers, where they can explore and purchase our products with ease, and in case of any questions or problems, our support team is always ready to help you and provide quick solutions.'
      ,img:cs,
      style:"rtl",
      mirror:"scaleX(1)",
      style_text:'start',
      color:"rgba(223, 144, 25, 1)"
    },
  ];
  const customerData = [
    {
      header: 'Fast and safe shipping',
      p:"Don't worry about shipping, exchange and return options, we take care of all shipping services for you, to provide you with a comfortable shopping experience.",
      img:car,
      style:"rtl",
      style_dir:"rtl",
      mirror:"scaleX(1)",
      style_text:'start',
      color:"rgba(136, 5, 13, 1)"
    },
    {
      header: 'Multiple payment systems',
      p:"sona3 offers various payment systems that suit all categories to facilitate the customer’s shopping experience, all of which are secure due to our commitment to the highest levels of security and digital protection."
      ,img:pay,
      style:"ltr",
      style_dir:"rtl",
      style_align:"right",
      mirror:"scaleX(-1)",
      style_text:'end',
      color:"rgba(10, 128, 106, 1)"
      
    },
    {
      header: '24/7 Purchasing',
      p:"Our products are available for purchase throughout the day without any opening or closing dates. You will not miss any opportunity to purchase when you register in our application."
      ,img:hour,
      style:"rtl",
      style_dir:"rtl",
      mirror:"scaleX(1)",
      style_text:'start',
      color:"rgba(223, 144, 25, 1)"
    },
  ];
  const articale_ads = [
    {_id:1, title:"Arrangement pottery elements workshop", date:"May 11, 2023", section:"E-Commerce",image:b1, views:"40,5840 Views"},
    {_id:2, title:"Beautiful handmade ceramics pottery concept", date:"May 11, 2023", section:"E-Commerce",image:b2, views:"40,5840 Views"},
    {_id:3, title:"Carpenter working on a piece of wood - High view", date:"May 11, 2023", section:"E-Commerce",image:b3, views:"40,5840 Views"},
    {_id:4, title:"Caucasian woman sewing homemade textile pattern indoors - Generated by AI", date:"May 11, 2023", section:"E-Commerce",image:b4, views:"40,5840 Views"},
  ]
  return (
    {customerData, sellerData, articale_ads}
  )
}


export const news = [
  { _id:1, text: "Arrangement pottery elements workshop", date: "", image: b1 },
  { _id:2, text: "Beautiful handmade ceramics pottery concept", date: "", image: b3 },
  { _id:3, text: "Carpenter working on a piece of wood - High view", date: "", image: b4 },
  { _id:4, text: "Caucasian woman sewing homemade textile pattern indoors - Generated by AI", date: "", image: b5 },
  { _id:5, text: "Close-up female African American hands making clay pottery", date: "", image: b6 },
  { _id:6, text: "Colorful needlework embroidery flowers as background", date: "", image: b7 },
  { _id:7, text: "From various angles, traditional bamboo bag webbing is decorated with Bohemian aesthetic", date: "", image: b8 },
  { _id:8, text: "Giving final touches to artwork", date: "", image: b9 },
  { _id:9, text: "Person using macramé technique", date: "", image: b10 },
  { _id:10, text: "Punch needle embroidery pillow DIY - Closeup skilled hands crafting cozy woolen masterpiece", date: "", image: b11 },
  { _id:11, text: "Top view hands knitting", date: "", image: b12 },
  { _id:12, text: "Turkish rug tapestry colorful symbol - Indigenous textile industry, generated by AI", date: "", image: b13 },
  { _id:13, text: "Unfinished jug", date: "", image: b14 },
  { _id:14, text: "Bag with rope it", date: "", image: b2 },
  { _id:15, text: "Young adult organizing their knitting products", date: "", image: b15 },
];



export const partners = [ 
  // partner_1, partner_2, partner_3, partner_4, partner_5, partner_6
  partner_7
 ]