import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Ar from "./lang/ar.json";

// Function to get query parameters
function getQueryParams() {
    return new URLSearchParams(window.location.search);
}

// Get the language from query parameters or fallback to localStorage or default to 'en'
const queryParams = getQueryParams();
const queryLang = queryParams.get('lang');
const storageLang = localStorage.getItem('lang');
const defaultLang = 'en';

let lng;
if (queryLang) {
    lng = queryLang;
} else if (storageLang) {
    lng = storageLang;
} else {
    lng = defaultLang;
}

i18n
    .use(initReactI18next)
    .init({
        resources: {
            // en:{translation: En},
            ar: { translation: Ar },
        },
        fallbackLng: 'en',
        lng: lng,
        debug: false,
        interpolation: { escapeValue: false, }
    });

export default i18n;
