import React ,{useState} from 'react'
import {BsWhatsapp} from 'react-icons/bs'
import style from './style.module.css'
import { useTranslation } from 'react-i18next'
export default function Whatsapp() {
const{i18n} = useTranslation()
const[message,seMessage] = useState(i18n.language ==="en" ? "Hello !" :'السلام عليكم ورحمة الله و بركاته')
const SetMessage = (e)=>{
    seMessage(e.target.value)
}
  return (
    <div className={style.whats_app_floating} id={style.Whats_beats} >
        {/* <input type="text" value={message} onChange={(e)=>SetMessage(e)} /> */}
        <a href={`https://wa.me/+971600505006?text=${message}`}  target="_blank" rel="noreferrer"><BsWhatsapp id={style.whatsapp_icon}/></a>
    </div>
  )
}
