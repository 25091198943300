import React from 'react'
import style from './styles/hero.module.css'
import { useTranslation } from 'react-i18next'
import { persons } from '../../../assets'
import DownloadAppButton from '../../../common/downloadAppButton/DownloadAppButton'
export default function Hero({color}) {
  const {t,i18n} = useTranslation()
  return (
    <div className={style.section} style={{background:`${color}`}}>
        <div className={style.block_content}>
            <h1 className={style.h1} data-aos="fade-up" >{t("Join the elite makers and unleash your creativity")}</h1>
            <h3 className={style.h3} data-aos="fade-up" >{t("Download the Makers app to start your journey")}</h3>
            <DownloadAppButton/>
        </div>
        <div className={style.persons_image}>
             <img className={style.image} src={persons} alt="persons" data-aos={i18n.language==="en"? "fade-left":"fade-right"} data-aos-duration="1000"/> 
        </div>
    </div>
  )
}
