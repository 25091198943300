import React from 'react'
import { useTranslation } from 'react-i18next'
import TermsConditionsEn from './TermsConditionEn'
import TermsConditionsAr from './TermsConditionsAr'

export default function TermsConditions() {
const {i18n} = useTranslation()

  return (
    <>
      {i18n.language === "en" ? <TermsConditionsEn/> : <TermsConditionsAr/>}
    </>
  )
}
