import React,{useEffect,useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header3 from './../../../common/Header3';
import CustomButton from './../../../common/CustomButton';
import { icon_1, icon_2, medal, vector, catalog, note, pdf_icon } from './../../../assets/index';
import style from './styles/about.module.css'
import SplitterHeader from '../../../common/SplitterHeader';
import { Button } from 'antd';

export default function About() {
  const {t,i18n} = useTranslation()
  const navigate = useNavigate()
  const[matches,setMatches] = useState(window.matchMedia("(min-width: 1100px)").matches)
  useEffect(() => {const handler = (e) => setMatches( e.matches ); window.matchMedia("(min-width: 1100px)").addListener(handler);},[])
  const mergedStyles = { fontFamily: i18n.language === 'en' ? 'GE_SS_bold' : 'GE_SS_light', ...style, };
  return (
    <div className={style.Section}>
      <SplitterHeader text="About Sona3"/>
      <div className={style.Row}>
          <div className={style.Column}>
       
            <Header3 classname={style.h3} dataAos={"fade-up"} text="The largest platform for every maker and innovator in the UAE"/>

            <p className={style.p} data-aos="fade-up">{t("Welcome to “Sona3”, the platform that combines art and craftsmanship to provide the most amazing products that reflect creativity and beauty. We offer many handmade products that have been crafted with care and expertise by talented craftsmen.")}</p>

            <div className={style.line} id={style.Line_1} data-aos="fade-up">
              <img className={style.icon} src={icon_1} alt="header"/>
              <span className={style.line_text}><bold className={style.line_text_bold}>{t("You'll find a diverse range of products, ")}</bold><span>{t("from traditional handicrafts to contemporary designs inspired by contemporary art.")}</span></span>
            </div>
            <div className={style.line} id={style.Line_2} data-aos="fade-up">
              <img className={style.icon} src={medal} alt="header"/>
              <span className={style.line_text}><bold className={style.line_text_bold}>{t("We care about details and quality, ")}</bold><span>{t("so we only work with experienced craftsmen who have exceptional skills in creating unique products.")}</span></span>
            </div>
            <div className={style.line} id={style.Line_3} data-aos="fade-up">
              <img className={style.icon} src={icon_2} alt="header"/>
              <span className={style.line_text}><bold className={style.line_text_bold}>{t("Whether you are looking for a piece of art to enhance your home, ")}</bold><span>{t("Or a special and special gift for someone dear to you, ”Sona3” is the ideal platform to meet your needs.")}</span></span>
            </div>
            <div className={style.pdf_buttons_flex}>
              <Button onClick={()=>{ window.open( i18n.language === 'ar' ? 'https://drive.google.com/file/d/1S_17zRmkeOE12-rBwdmb1CnY1EKxHgOy/view' :`https://drive.google.com/file/d/1cNP3cxZxjOpxY44YKAHwHsbgFfv4SBU5/view`, '_blank')}} className={`${style.file_button} ${style.company_button}`} data-aos="zoom-in" style={mergedStyles}>
                {t("Company Profile")} <img className={style.btn_img} src={pdf_icon} alt="pdf_company"/>
              </Button>
              <Button onClick={()=>{window.open(i18n?.language === 'ar'? 'https://drive.google.com/file/d/1M9tPNkyILJWvjG0DzUWUpueoGcqx02CU/view': `https://drive.google.com/file/d/1YdorNxdJHJpUYHZnc1IRiyDgyW9Hfnr8/view`, '_blank')}} className={`${style.file_button} ${style.seller_button}`} data-aos="zoom-in" data-aos-delay="100" style={mergedStyles}>
                {t("Seller Profile")} <img className={style.btn_img} src={pdf_icon} alt="pdf_seller"/>
              </Button>
            </div>
          </div>
          <img className={style.catalog} src={catalog} alt="header" data-aos="zoom-in" data-aos-delay="100"/>
      </div>
      <div className={style.Row_2_content}>
          <div className={style.Row_2}>
            <div className={style.Card}  data-aos="zoom-in">
                <img className={style.image} src={vector} alt="header" />
                <h1 className={style.Card_header}>{t("Our Vision")}</h1>
                <p>{t("A unique platform with its quality, distinguished by its originality, influential in community development and an incubator for every maker in the UAE.")}</p>
            </div>
            <div className={style.Card}  data-aos="zoom-in">
                <img id={style.message_image} className={style.image} src={note} alt="note" />
                <h1 className={style.Card_header}>{t("Our Message")}</h1>
                <p>{t("Our mission is to help and empower owners of home projects and handicrafts, highlight the creative hands of people of determination, and be the link between them and the buyer by providing an easy and innovative platform that brings together all makers to display their unique, handcrafted products.")}</p>
            </div>
          </div>
          <CustomButton onClick={()=>{navigate("/about")}} id={style.Button} text={"More Details"}/>
      </div>
    </div>
  )
}
