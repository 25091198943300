import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import style from '../style/style.module.css';
import { Image } from 'antd';
import { frame } from '../../../assets';
import { Name } from '../../../utils/functions';
import SubCategories from './SubCategories';
import useGetData from './../../../api/useGetData';
import '@splidejs/splide/css';
import '../style/splide.css';
import './style.css';

export default function Slider({ hideSubs }) {
  const { i18n } = useTranslation();
  const { data, loading } = useGetData({ route: 'customer/categories/list', params: { isSubCategory: false, type: "shop", isActive: true } });
  const [currentID, setCurrentCategoreyID] = useState("1");
  const [category, setCategory] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const splideRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);

  useEffect(() => {
    if (data && !loading) {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get('id');
      if (id) {
        const item = data.find((items) => items._id === id);
        if (item) {
          setCategory([item]);
          setCurrentCategoreyID(id);
        }
      } else {
        setCategory([data[0]]);
      }
    }
  }, [data, loading, location.search]);

  const handleUrl = (id) => {
    navigate(`/categories?id=${id}`);
  };

  return (
    <div>
      <Splide aria-label="Categories" id="categories_page_splide"
        ref={splideRef}
        options={{
          start: 0,
          rewind: false,
          width: '100%',
          gap: '0.2rem',
          perPage: 5,
          direction: i18n.language === 'en' ? 'ltr' : 'rtl',
          lazyLoad: 'nearby',
          breakpoints: {
            1100: {
              perPage: 4,
            },
            800: {
              perPage: 3,
            },
            500: {
              perPage: 3,
              gap: '1.8rem',
            },
          },
        }}
      >
        {data && data.map((item, i) => (
          <SplideSlide key={i}>
            <div className={style.Card} id={i} onClick={() => {
              if (!hideSubs) {
                setCurrentCategoreyID(item?._id);
                setLoadingCategory(true);
                setCategory([]);
                setTimeout(() => {
                  setCategory([item]);
                  setLoadingCategory(false);
                }, 800);
              }
              handleUrl(item?._id);
            }}>
              <div className={style.circle}>
                <Image className={!hideSubs ? (currentID === item?._id ? `${style.circle_image} ${style.imgActive}` : style.circle_image) : `${style.circle_image}`} src={frame} preview={false} />
                <div className={style.label}>{item?.[Name(i18n.language)]}</div>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
      {!hideSubs && <SubCategories loadingCategory={loadingCategory} category={category} />}
    </div>
  );
}
