import React from 'react'
import Header1 from './Header1'
import style from './style.module.css'
import { s_line } from '../assets'
export default function SplitterHeader({text, customStyle}) {
  return (
    <div className={style.Header} style={customStyle} data-aos="flip-up" data-aos-duration="2000">
        <img className={style.s_line} src={s_line} alt={text} data-aos="fade-right" data-aos-duration="2000"/>
          <Header1 classname={style.h1} text={text}/>
        <img className={style.s_line} src={s_line} alt={text} data-aos="fade-left" data-aos-duration="2000"/>
    </div>
  )
}
