import React from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import style from './style.module.css'
import { ar, en } from '../../assets';
import { useNavigate } from 'react-router-dom';
const LangDropDown = () => {
  const{i18n} = useTranslation()
  const navigate = useNavigate(); // Use useNavigate hook
  const handleLanguageChange = (value) => {
    let queryParams = new URLSearchParams(window.location.search);
    i18n.changeLanguage(value);
    localStorage.setItem('lang', value);
    queryParams.set("lang", value )
    navigate(`?${queryParams.toString()}`); // Use navigate to navigate
  };

  const menu = (
    <Menu>
      <Menu.Item key="ar" onClick={()=>{handleLanguageChange('ar')}}>العربية</Menu.Item>
      <Menu.Item key="en" onClick={()=>{handleLanguageChange('en')}}>English</Menu.Item>
    </Menu>
  );

  return (
    <nav>
          <Dropdown overlay={menu}>
            <Button  id={style.LangBadge_BTN}>
              {/* <DownOutlined/> */}
              <img className={style.image_lang} src={i18n.language==='en'? en : ar } alt="flag"/>
            </Button>
          </Dropdown>
    </nav>
  );
};

export default LangDropDown;
