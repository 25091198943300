import React from 'react'
import { h_line } from '../../assets'
import useApi from '../../components/Loading/LoadingApi'
import BreadCrumb from '../../components/Layout/BreadCrumb'
import { useTranslation } from 'react-i18next'
import style from './style.module.css'
import { BsClockHistory } from "react-icons/bs";

export default function ComingSoon() {
useApi(600)
const {t} = useTranslation()
  return (
    <div>
       <img className={style.image_h_line} src={h_line} alt='h_line' />
       <BreadCrumb/>
       <div className={style.content}>
        <BsClockHistory className={style.img}/>
        <h1 className={style.header}>{t("Coming Soon")}</h1>
        </div>
    </div>
  )
}
