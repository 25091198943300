import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import style from './styles/breadcrumbs.module.css';
import { useTranslation } from 'react-i18next';

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return text.substr(0, maxLength) + '...';
}

export default function BreadCrumb({tripple}) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <div>
      <Breadcrumb id={tripple ? style.BreadcrumbTripple : style.Breadcrumb}>
        <Breadcrumb.Item>
          <Link to="/"  style={{fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}}>{t("Home")}</Link>
        </Breadcrumb.Item>
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const isLast = index === pathnames.length - 1;
          const decodedName = decodeURIComponent(name); // Decode URL-encoded spaces
          const truncatedName = truncateText(t(decodedName), 25); // Adjust maximum length as needed
          return (
            <Breadcrumb.Item key={index}>
              {isLast ? (
                <span className={style.bread_active} style={{fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}}>{truncatedName}</span>
              ) : (
                <Link  style={{fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}} to={`${routeTo}?page=1&limit=5`}>{truncatedName}</Link>
              )}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
}
