import { Button } from 'antd'
import React from 'react'
import style from './style.module.css'
import { apk, ios } from '../../assets'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
export default function DownloadAppButton({classname}) {
const {t, i18n} = useTranslation()
const navigate = useNavigate()
const mergedStyles = { fontFamily: i18n.language === 'en' ? 'GE_SS_bold' : 'GE_SS_light', ...style, };

  return (
    <div className={`${style.flex_btns} ${classname}`}>
            <Button style={mergedStyles} data-aos="zoom-in" data-aos-duration="500" onClick={() => { window.open('https://play.google.com/store/apps/details?id=com.sellermobapp', '_blank'); }}  className={style.button}><img className={style.icon_img} src={apk} alt="Android" /> <div className={style.text}>{t("Available on Play Store")}</div> </Button>
            <Button style={mergedStyles} data-aos="fade-in" data-aos-duration="800" onClick={() => { navigate('/comingsoon')}}  className={style.button}><img className={style.icon_img} src={ios} alt="ios" /> <div className={style.text}>{t("Available on App Store")}</div> </Button>
    </div>
  )
}
