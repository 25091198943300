import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { NavLink } from 'react-router-dom';
import style from './style.module.css';
import BreadCrumb from './../../components/Layout/BreadCrumb';
import useApi from '../../components/Loading/LoadingApi';
import { SwalAlert } from '../../components/alert/SwalAlert';
import { gps_solid as gps, phone_solid, mail_solid, s_line, h_line   } from '../../assets';
import { FaXTwitter,FaInstagram  } from "react-icons/fa6";
import { LuLinkedin } from "react-icons/lu";
import { FiFacebook } from "react-icons/fi";
import { TbBrandSnapchat, TbBrandTiktok } from "react-icons/tb";
import SplitterHeader from '../../common/SplitterHeader';

const Support = () => {
  useApi(600);
  const { t, i18n } = useTranslation();
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First name is required')),
    lastName: Yup.string().required(t('Last name is required')),
    email: Yup.string().email(t('Invalid email')).required(t('Email is required')),
    phone: Yup.string().required(t('Phone number is required')),
    note: Yup.string().required(t('Message is required')),
  });

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    note: '',
  };

  const handleSubmit = (values,{resetForm}) => {
    // Handle form submission logic here
    SwalAlert({ text: t("We will be in touch with you soon"), status: true })
    resetForm()
    // You can add additional logic here, such as sending the form data to a server
  };

  return (
    <div>
      <img className={style.image_h_line} src={h_line} alt="h_line" />
      <BreadCrumb />
      <SplitterHeader text="Be In Touch"/>
      <div className={style.p}>
        {t(
          'You can easily contact us by calling or sending an email or WhatsApp message. Our team is ready throughout the day to receive your inquiries.'
        )}
      </div>
      <div className={style.container}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <Form className={style.Form} style={{ direction: i18n.language === 'en' ? 'ltr' : 'rtl' }}>
        <div className={style.clientName_div}>
          <div className={style.Input_info}>
            <label className={style.Label}>{t('firstName')}</label>
            <Field
              name="firstName"
              placeholder={t('firstName')}
              className={style.Input}
              as={Input}
            />
            <ErrorMessage name="firstName" component="div" className={style.error} />
          </div>
          <div className={style.Input_info}>
            <label className={style.Label}>{t('lastName')}</label>
            <Field
              name="lastName"
              placeholder={t('lastName')}
              className={style.Input}
              as={Input}
            />
            <ErrorMessage name="lastName" component="div" className={style.error} />
          </div>
        </div>
        <div className={style.Input_info}>
          <label className={style.Label}>{t('E-mail')}</label>
          <Field
            name="email"
            placeholder={t('E-mail')}
            className={style.Input}
            as={Input}
          />
          <ErrorMessage name="email" component="div" className={style.error} />
        </div>
        <div className={style.Input_info}>
          <label className={style.Label}>{t('Phone')}</label>
          <Field
            name="phone"
            placeholder={t('Phone')}
            className={style.Input}
            as={Input}
          />
          <ErrorMessage name="phone" component="div" className={style.error} />
        </div>
        <div className={style.Input_info}>
          <label className={style.Label}>{t('Message')}</label>
          <Field
            name="note"
            placeholder={t('Type your message here...')}
            className={style.Input}
            as={TextArea}
          />
          <ErrorMessage name="note" component="div" className={style.error} />
        </div>
        <Button id={style.send_btn} htmlType="submit">
          {t('Send')}
        </Button>
      </Form>
    </Formik>
        <div className={style.Data_Container}>
          <div className={style.Data}>
            <div className={style.Data_header}>{t('Communication Data')}</div>
              <div className={style.line}><img src={gps} className={style.social_icon} alt="1"/><span>{t("Abu Dhabi")}</span></div>
              <div className={style.line} style={{direction:"ltr", textAlign:i18n.language==="en"?"start":"end"}}>
                {i18n.language==="en" ? 
                <>
                <img src={phone_solid} className={style.social_icon} alt="1"/>
                <a href="tel:+971600505006" style={{color: "inherit", textDecoration: "none", fontFamily:"sans-serif"}}>+971 60 050 5006</a>
                </>
                : 
                <>
                  <a href="tel:+971600505006" style={{color: "inherit", textDecoration: "none", fontFamily:"sans-serif"}}>+971 60 050 5006</a>
                  <img style={{transform:"scaleX(-1)"}} src={phone_solid} className={style.social_icon} alt="1"/>
                </>
                }
              </div>
              <div className={style.line}><img src={mail_solid} className={style.social_icon} alt="1"/><span style={{fontFamily:"sans-serif"}}>info@sona3.ae</span></div>
            <div className={style.Social_Icons}>
                <NavLink ><FiFacebook onClick={()=>{window.open(`https://www.facebook.com/sona3app`, '_blank')}} className={style.social_icon} alt="1"/></NavLink>
                <NavLink ><FaXTwitter onClick={()=>{window.open(`https://twitter.com/Sona3app`, '_blank')}} className={style.social_icon} alt="2"/></NavLink>
                <NavLink ><FaInstagram onClick={()=>{window.open(`https://www.instagram.com/sona3app/`, '_blank')}} className={style.social_icon} alt="3"/></NavLink>
                <NavLink ><TbBrandSnapchat  onClick={()=>{window.open(`https://www.snapchat.com/add/sona3app`, '_blank')}} className={style.social_icon} alt="4"/></NavLink>
                <NavLink ><LuLinkedin  onClick={()=>{window.open(`https://www.linkedin.com/company/sona3app/`, '_blank')}} className={style.social_icon} alt="4"/></NavLink>
                <NavLink ><TbBrandTiktok onClick={()=>{window.open(`https://www.tiktok.com/@sona3app`, '_blank')}}  className={style.social_icon} alt="4"/></NavLink>
            </div>
          </div>
          {/* <div className={style.Map}>
            <div className={style.map}>{t('Map')}</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Support;
