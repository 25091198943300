import style from './style.module.css'
import useApi from './../../components/Loading/LoadingApi';
import { useTranslation } from 'react-i18next';
import BreadCrumb from './../../components/Layout/BreadCrumb';
import { h_line, s_line } from '../../assets';

export default function TermsConditionsEn() {
useApi(600)
const{t, i18n} = useTranslation()

  return (
    <>
        <img className={style.image_h_line} src={h_line} alt='h_line' />

    <div className={style.Content} style={{direction:i18n.language === 'en' ? "ltr" : "ltr", textAlign:i18n.language === 'en' ? "justify" : "justify"}}>
        <BreadCrumb/>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>{t("Terms & Conditions")}</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        The terms and conditions under which purchases are supplied and delivered to you as a buyer on SONA3.ae Website or on our mobile application (collectively referred to as the “Site”) are owned by (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) in the United Arab Emirates under license number CN-4899615 at the Department of Economic Development in Abu Dhabi.
        </p>
    </div>

    <div>
        <p dir="ltr">
        These terms and conditions, hereinafter referred to as the "Terms", govern the sale and supply of all products and equipment, and all services provided to the customer by SONA ETRANSACTION COMPLEX - L. L. C - O. P. C (hereinafter referred to as “SONA ETRANSACTION COMPLEX - L. L. C - O. P. C").
        </p>
    </div>

    <div>
        <p dir="ltr">
        These are the general terms and conditions (“Terms”) that are concluded between you and (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C).
        </p>
    </div>

    <div>
        <p dir="ltr">
        With regard to your use of this site, your access to it, and your use of the mobile services of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C), the services of the dial-up and voice response unit at (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C), and the software applications for the (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) Website that were downloaded to the desktop. Your e-mail addresses, and the other sites and services on which these terms have been published or referred to (“SONA ETRANSACTION COMPLEX - L. L. C - O. P. C”) and the promises and obligations stipulated here, which you, in turn, intend to be legally bound by, you and (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) agree to the following: 
        </p>
    </div>

    <div>
        <p dir="ltr">
        Your access to and use of the services of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) is subject to these terms, which include the privacy policy statement and other separate publications or policies, in addition to any amendments added by (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) in addition to all applicable laws and regulations
        </p>
    </div>

    <div>
        <p dir="ltr">
        If any of SONA3 Services has separate terms and conditions of use, privacy statement or other policy (“Separate Terms”), then those separate terms, which may be amended from time to time, shall apply in any action related to your use of those Services set forth by SONA3. In the event of any direct conflict between the Separate Terms and these Terms, the Separate Terms shall prevail.
        </p>
    </div>

    <div>
        <p dir="ltr">
            By using SONA3 Services, you agree to be bound by these Terms and any other applicable policies. If you do not wish to be bound by these terms or policies, do not use SONA3 Website services.
        </p>
    </div>

    <div>
        <p dir="ltr">
        SONA3 services provide information related to various products and services, in addition to the opportunity to obtain additional information regarding those products and services and the opportunity to purchase them. These terms and information provided by SONA3 Website services do not in any way exceed the terms and conditions of purchase for any product or service except as specified here. In the event that any section within the Site or any feature provided by SONA3 Site and Services includes specific terms and conditions regarding its use (“Specific Terms”), such specific terms shall be added to these Terms and shall apply. In the event of any direct conflict between these Terms and the Specific Terms, the Specific Terms shall prevail. 
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Introduction</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        - SONA3 Website is an electronic platform built by Emirati hands, designed to be a home for every maker and creator in various fields. It was designed with passion to feature unique technologies and design that allows the buyer to get everything he is looking for with ease and also allows manufacturers to upload their products and services easily and display them in an attractive and distinctive way.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - The “SONA3” platform provides creatives and makers with the opportunity to turn their ideas into reality by providing innovative and effective means to market their products and reach a wider audience. With the “SONA3” application, you can join a creative community.  
        </p>
    </div>

    <div>
        <p dir="ltr">
        - It is important that you read and understand the agreement below. Your use of this Website signifies your acceptance of this Agreement and any changes made after commencement of use.
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Changes in Terms</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
     <div>
        <p dir="ltr">
        - SONA3 Website has the right at any time and without prior notice, based on its sole discretion, to revise these terms or impose new terms and conditions regarding access to or use of SONA3 Website services. Such revisions and additions shall be effective immediately upon notice thereof, which may be provided by any means, including, without limitation, by posting the revised or additional terms and conditions on SONA3 Services.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - You are responsible for reviewing these Terms periodically, for any modification to these Terms that may affect your rights or obligations under this Agreement.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - You agree that you shall be deemed to be aware of any modification by SONA3 to these Terms. Any access or use of SONA3 Services by you after amendments or additions to these terms shall be considered your acceptance of these amendments or additions. 
        </p>
    </div>

    <div>
        <p dir="ltr">
        - You agree to comply with all applicable laws, regulations and decrees regarding your use of SONA3 Website services and your purchase of products or services through such laws. No modification of these Terms by any party other than SONA3 shall be valid or enforceable against SONA3 unless expressly agreed to by SONA3 in a writing signed by a duly authorized officer of SONA3.
        </p>
    </div> 
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Close the Account</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        
    <div>
        <p dir="ltr">
        - These terms are effective until terminated by SONA3. SONA3 may terminate these Terms without notice and at any time with respect to any SONA3 Website services.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - In the event of termination, access to SONA3 Services shall no longer be authorized to you, and the restrictions imposed on you with respect to the content, disclaimers, indemnities, and limitations of liabilities set forth in these Terms shall apply.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - SONA3 shall also have the right at any time and without any prior notice to terminate some or all of SONA3 Website services or any feature or part thereof, or any products or services provided through them, or to terminate any person’s right to access SONA3 Website services or use or access and use any feature or part thereof. 
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Content</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        -The text, images, graphics, logos, illustrations, descriptions, data and other materials provided by SONA3 Website on or through SONA3 Website services, as well as the selection, compilation and arrangement thereof, are collectively referred to as “Content”.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - The content may include some unintentional errors or typographical errors, or the content may be out of date. SONA3 Website may change, delete or update any content at any time and without prior notice. The Content is provided for informational purposes only and the Site is not deemed obligated to do so in any way except to the extent specifically indicated.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - Unless otherwise indicated, all content is protected by copyright, trademark rights, service mark rights, and other proprietary rights, all of which are owned by SONA3 Website or by third parties who have licensed their use to SONA3 Website. You may view and use the Content only for your personal information and to shop and place orders on or through SONA3 Website services, and for no other purpose, and you must retain all copyright and other proprietary notices. Except as provided in the foregoing, SONA3 Website does not grant to you or any other person any right to use, reproduce, copy, modify, transmit, display, publish, sell, license, create derivative works, publicly perform or distribute by any means, method, or process whatsoever, any of the content existing or transmitted through SONA3 Website services, currently known or currently being developed, including, but not limited to, transferring, downloading, or copying any content to any drive or another storage medium. Any use of the Content, except as specifically permitted in these Terms or as expressly permitted in the Content or in a signed writing from Site SONA3, is strictly prohibited.  
        </p>
    </div>

    <div className={style.header} >
        <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Linked Sites operated by Third Parties</h1>
        <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
    </div>  

    <div>
    <p dir="ltr">
    Links to other Internet sites operated by third parties, including SONA3.ae vendors, do not constitute sponsorship, endorsement, or approval by SONA3.ae of the content, policies, or practices of such linked sites. The Linked Sites are not operated, controlled or maintained by SONA3 Site, and SONA3 Site is not responsible for the availability, contents, security, policies or practices of the Linked Sites, including but not limited to the privacy policies and practices. Links to other sites are provided for your convenience only, and access to them is at your own risk. 
    </p>
    </div>
   
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Purchase and Sale Transactions</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
        - By using SONA3 Website services, buyers and sellers shall create legally binding contracts for the sale of goods and services. Both parties must abide by the arrangement for the sale of goods or services.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - Users who conduct purchase and / or sale transactions must be aware of and bear the risks of using Websites and electronic payment procedures. Users should be aware of other users who may be acting under a false pretense, or with the intent to commit fraud.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - While SONA3 takes precautionary measures to ensure the identity and authority of users who sign electronically to use the Site service, we cannot and do not confirm the identity of all parties who use SONA3.ae service. We suggest all users to use common sense and encourage them to contact SONA3 to report any suspicious behaviour or misappropriation of the Site Service. SONA3 do not control the content posted by users. If you find content provided by other users that you consider offensive, harmful or offensive, you may contact us via <a href="mailto:info@sona3.ae" style={{color: "inherit", textDecoration: "none"}}>info@sona3.ae</a> . SONA3 provides an open community and embraces the freedom of expression of users on the Site within the limitations set forth in these Terms and Conditions and the decisions made by SONA3.ae employees.
        </p>
    </div>

        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}> Prices and Orders</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
    <p dir="ltr">
    - SONA3 shall add shipping and handling fees and applicable sales / use tax on top of the price paid by the buyer. 
    </p>
    </div>

    <div>
        <p dir="ltr">
        - All our shipping fees are calculated based on the volumetric weight of the product. The volumetric weight of the product is: width x length x height in centimeters / 5000 = volumetric weight in kilograms.
        </p>
    </div>

    <div>
        <p dir="ltr">
        - SONA3 Website reserves the right, without prior notice, to stop or change the specifications and prices on the products and services provided through SONA3 Website services without incurring any obligation towards you. Descriptions or references to products or services provided by SONA3 Website do not imply support for that product or service, and do not constitute any warranty of its kind by SONA3 Website.  
        </p>
    </div>

    <div>
        <p dir="ltr">
        - Your order confirmation receipt does not constitute SONA3 Website’s approval of the order. Before the Site accepts the order, it may require verification of the information and approval of the order. SONA3 Website reserves the right at any time after receiving your order to accept or reject your order, or any part of it, even after you receive the order confirmation message from SONA3 Website, for any reason. SONA3 reserves the right to limit the order quantity on any product and refuse service to any customer without prior notice. 
        </p>
    </div>

    <div>
        <p dir="ltr">
        - In the event that an incorrect price is listed for a product or service due to incorrect information from sellers or a typographical error, SONA3 has the right to refuse or cancel orders placed for the product or service listed at the incorrect price, regardless of whether the order has been confirmed. 
        </p>
    </div>

    <div>
        <p dir="ltr">
        - The risk of loss for all products that you purchase and are shipped by SONA3 Website is transferred to you when SONA3 Website delivers the product to the shipping company to deliver it to you. 
        </p>
    </div>

        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Cancelling The Orders</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
            <strong>A-</strong> Customers who qualify for the cancellation policy or the return goods policy, the returning refunds process shall take up to 14 to 21 business days. Please contact us at (<a href="tel:+971600505006" style={{color: "inherit", textDecoration: "none", fontFamily:"sans-serif"}}>+971 60 050 5006</a>).
        </p>
        <p dir="ltr">
            <strong>B-</strong> The customer’s order can be canceled and the amount paid can be refunded if the following occurs:
        </p>
        <ol className='sell_with_us' dir="ltr">
            <li>If the seller does not accept the order, otherwise if the seller accepts the order, the cancellation policy is invalid.</li>
            <li>If the seller cancels the order.</li>
            <li>In the event of any technical problem with the application.</li>
            <li>SONA3 has the right to decide to cancel any customer's order if there is suspicion that the customer's account is fake.</li>
        </ol>
    </div>

    <div>
        <p dir="ltr">
        <strong>B-</strong> Refunds shall be returned to the payment method the customer initially used.        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong>C-</strong> You can cancel the order and get a full refund until the order is accepted by the seller. If you need to cancel the order after it has been accepted by the seller, please contact us.
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong>D-</strong> SONA3 has the right not to issue full or partial refunds if you cancel the order after accepting it.
        </p>
    </div>
    <div>
        <p dir="ltr">
        <strong>E-</strong> Customers cannot cancel the order if they choose the payment service (cash on delivery) except for customers who are eligible for the return goods policy according to the terms and conditions in the refunds and returned goods section.
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong>F-</strong> SONA3 has the right to cancel any order, before or after acceptance, and you shall be notified immediately of any such cancellation.
        </p>
    </div>

        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Deliver Your Order</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
        Delivery Cost: The cost of delivering products is according to the prices mentioned on the Website.
        </p>
        <p dir="ltr">
        Delivery Date: This information shall be displayed to you on the Website.
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong>Delay in Delivery:</strong> If our delivery of the product is delayed for reasons beyond our control, we shall contact you as soon as possible to inform you of the same, and we shall take steps to minimize the consequences of delay in delivery.
        </p>
        <p dir="ltr">
        If there is no one at your address to receive the product and the product cannot be mailed to your mailbox, we shall inform you in a timely manner how the product shall be delivered or received by you.
        </p>
        <p dir="ltr">
        If you are unable to collect the product from us as agreed or you are unable to reschedule delivery of the product after it cannot be delivered to you at your address, we shall contact you for further instructions. We shall cancel your order in accordance with these Terms of Sale if we are unable to contact you or arrange a new delivery or pickup time despite our continuous efforts in this regard.
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong> Identification Requirements upon Delivery:</strong> SONA3 may, at its sole discretion, make any inquiries it deems necessary to verify your identity and /or ownership of your Financial Instruments by requesting that you be provided with further information or documentation, including, but not limited to, requesting any form of identification and / or credit card. If SONA3 is unable to verify the authenticity of any information you provide, SONA3 has the right to refuse delivery and cancel the order.  
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong> Delivery from Outside the Country:</strong>You may be registered as an importer if you request the delivery of products from outside the country. In this case, you must verify that the requested products comply with the law and regulations, and you must also commit to paying all duties and customs imposed on your purchases.
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong>Ownership of the Products:</strong> The products become your property once we deliver them to you at the delivery address and you pay for their full value.
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Payment Services in SONA3</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
    <p dir="ltr">
    SONA3, through its partners, provides payment system in (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C). It can be done entirely online through the payment options available on the “SONA3” platform or through any payment method provided by (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) from time to time, for example:
    </p>
    <ul dir="ltr">
        <li>- Through a credit or debit card.</li>
        <li>- Through your electronic wallet.</li>
        <li>- Cash on delivery (an amount not exceeding AED 2,500).</li>
    </ul>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Your Personal Information and Transaction Details</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
        <span>A-</span> The user acknowledges that he grants (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) an unlimited, global, perpetual, irrevocable, royalty free, and licensed right to use personal information or materials or other materials provided by them or provided to the “SONA3” platform or announced on the platform through your joining or use of it, through the designated forms for communication and registration, or via any electronic message or any of the communication channels available on the platform, with the aim of achieving any of the interests that the platform deems appropriate.
        </p>
        <p dir="ltr">
        <span>B-</span> The provisions for the confidentiality of consumer information are subject to the rules of the “Privacy Policy and Confidentiality of Information” of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C), and the provisions contained in this agreement related to the confidentiality of information. 
        </p>
        
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Disclaimer and Limitation of Liability Regarding SONA3 Website and the Content Contained Therein</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
        SONA3 Site makes no warranties or representations whatsoever regarding the Site Services or any Site or content linked thereto, including the availability, accuracy, completeness or suitability of any sites, content, information and materials related thereto. SONA3 Website also does not guarantee to you that your access to or use of any of SONA3 Website services or any linked Site shall be uninterrupted or free of errors or deletions, or that defects shall be corrected, or that SONA3 Website services or any linked Site shall be free of viruses. or other harmful ingredients. Without limiting the foregoing, all content on or through SONA3 Site Services is provided to users “as is,” without warranties of any kind, whether express or implied, including but not limited to the implied warranties of merchantability and fitness for a particular purpose, non-infringement, security, or accuracy. 
        </p>
        <p dir="ltr">
        The condition of the content that we refer to as “as is” is considered a description of any dealings arising from or as a result of SONA3’s services. Please note that some jurisdictions may not allow the exclusion of implied warranties, so some of the above exclusions may not apply to you. Check your local laws for any restrictions or restrictions regarding the exclusion of implied warranties. 
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Compensation</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
   <div>
        <p dir="ltr">
        You agree to defend SONA3 Website and SONA3 Website sellers, including our parent company, subsidiaries, affiliates, employees, or their directors, members of the boards of directors, employees, agents, suppliers, subcontractors, or licensors, from and against all claims, losses, damages, liabilities, and costs including, without limitation, reasonable attorneys' fees and court costs, arising out of or relating to a violation of these Terms or your access to or use of SONA3 Site Services. The foregoing indemnification obligation shall survive termination of these Terms and termination of operation of SONA3 Site Services or any Product or a service provided to you arising from or related to your use of SONA3 Website services. 
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Submissions </h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 

    <div>
        <p dir="ltr">
        Except as expressly provided otherwise or in the privacy statement posted on the separate SONA3 Site, any communication or material you submit to SONA3 Site through the Site Services by electronic mail or otherwise, including without limitation any contest or participation in Lots, images, video or audio files, articles, questions, comments, suggestions, or any ideas for features, modifications, improvements, products, technologies, content, offers, promotions, strategies, product feature names, or any related documentation or artwork, computer code, graphics, or other materials (collectively, “Submissions”) shall be treated as non-confidential and non-proprietary and may be used by SONA3 Site, SONA3 Sellers, its affiliates or related entities for any purpose, including but not limited to modification, disclosure, transmission, publication, broadcast, advertising, or publicity or promotional purposes in any media without permission, approval, payment or any other consideration, unless prohibited by law. Furthermore, the vendors of SONA3 Website and SONA3 Website, its affiliates and related entities are free to use any files contained in any communication you send to SONA3 Website through SONA3 Website services for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing products or services using the information contained in these submissions.    
        </p>
        <p>
        By using SONA3 services, you agree that SONA3 Website may communicate with you via SMS text messages or other electronic means on your mobile device, and that some information about your use of SONA3 services may be communicated to SONA3 Website. If you change or cancel your mobile phone number, you agree to immediately update your mobile subscription account information to ensure that messages that SONA3 intends to send to you are not sent to someone else who obtains your previous mobile phone number. The number of alerts you shall receive varies based on your choices and how often you order from SONA3 Website.    
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Privacy Policy</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
        On the SONA3 Website, we are committed to maintaining the privacy of our members and ensuring that the personal information you provide to us is secure. SONA3 needs to process the personal data and information of buyers and sellers to operate the business and provide you with services. By accepting our terms of use, you confirm that you have read this policy and that you agree to us using your information in the ways we explain. If you do not want us to collect or process your personal information in the ways described in this Policy, you should not use the Services. We are not responsible for the content or privacy policies of any of our members, third party Websites, or third-party applications.  
        </p>
        <p dir="ltr">
        We use the information we collect from users in several ways. For example, if you purchase a product, we shall share your address information with the seller; Your Site suggestions may be used by us to improve the Services; We use data analytics and work with certain third-party service providers to ensure the operational functionality of the Site, to improve the Services, for targeted online advertising and marketing, and of course we use your email address to communicate with you. SONA3 shall not sell or disclose your personal information to third parties without your consent, except as specified in our Privacy Policy.
        </p>
        <p>
        In order to be able to use our services, you need to provide us with a valid email address, and for the registration process, we need a name associated with your account that you can choose and that represents your identity on the SONA3 Website. You can review and change this name through your account settings. This information must be provided to enable us to provide you with the Services. Depending on the Services you choose to use, additional information, such as store name, billing and payment information (including billing address, phone number, and credit card information), telephone number, and / or physical mailing address may be required in order for us to provide a particular Service. You are not required to provide us with this information to sign up, but we shall need it to provide certain services. For example, we need a physical mailing address if you buy something on the Site for delivery. 
        </p>
        <p>
        You may choose to provide your full name and other personal information (such as birthday, gender, and location) and display it publicly in connection with your account and activity. If you do not want this additional information to be displayed publicly, you can remove it through your account settings.
        </p>
        <p>
        The name associated with your account, which you can review by changing your account settings, is publicly displayed and associated with your activity on the SONA3 site. Other people may see the date you joined, your reviews, ratings, profile information, and comments in social spaces. 
        </p>
        <p>
        The Website uses cookies, web beacons and similar technical tools to collect information about your access to the Website and the services we offer. Please note that blocking cookies shall have an impact on the functionality of the Site and may prevent you from using the Services. Cookies are pieces of information that include a unique referral code that a Website transfers to your device to store and sometimes track information about you. A number of the cookies we use only last for the duration of your web session and expire when you close your browser. Other cookies are used to remember you when you return to the Website and to continue for longer.
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Refunds and Returned Goods</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
    <p dir="ltr">
    By registering to sell on the SONA3 Website, the seller agrees to provide refunds to buyers who do not receive their products; or who receive the product late; or if the product does not match its specifications mentioned in the description. This is done as shown below: 
        <br></br>
        <strong>A-</strong> No Delivery: If the Buyer does not receive a product, as described below, the Seller shall refund the amount paid to the Buyer:
        <ul>
            <li><strong>1- </strong>If the buyer does not receive the requested product because the product was not sent.</li>
            <li><strong>2- </strong>If the buyer does not receive this product because it was not sent to the address on the SONA3 Website.</li>
            <li><strong>3- </strong>If there is no evidence that the product was shipped to the requested address. </li>
        </ul>
        <strong>B-</strong> Late Delivery: If the Buyer provides proof that all of these conditions have been met, the Seller must refund the amounts paid to the Buyer:
        <ul>
            <li><strong>1- </strong>If the product is requested for a specific date or occasion.</li>
            <li><strong>2- </strong>If the product is considered useless after that date. </li>
            <li><strong>3- </strong>If the seller does not ship the product according to the time it was processed or on the date agreed upon in the negotiations.</li>
        </ul>
        <strong>C-</strong>The product does not conform to the mentioned specifications: If the buyer proves that any of the following is true, the seller must refund the amount paid to the buyer:
        <ul>
        <li><strong>1- </strong>If the product is completely different from the listing description or pictures.</li>
        <li><strong>2- </strong>If the product received is of a different color, model, version or size than what is shown in the picture or as described in the description.</li>
        <li><strong>3- </strong>If the product has a different design or material.</li>
        <li><strong>4- </strong>If the product is declared original and it turns out otherwise.</li>
        <li><strong>5- </strong>If the seller failed to disclose the fact that a product was damaged or parts of it were missing.</li>
        <li><strong>6- </strong>In the event that the product sustains damage and it is established that the improper packaging caused the harm.</li>
        <li><strong>7- </strong>If the quantity of the products ordered is different from what was received.</li>
        <li><strong>8- </strong>If the product condition is described incorrectly. For example, the product description stated at the time of purchase that the product was “new” and it turned out to be used. </li>
        </ul>
        You are responsible for any non-conformity or defect in any of your Products or other products associated with your Products or any general or specific recall thereof. You shall notify us immediately as soon as you become aware of any general or specific recall of your products or other products related to your products.
        </p>
        <p>
         The buyer must notify us if one of the above reasons exists, provided that he is obligated to notify us within two days of receiving the product.
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}> Food and Drinks</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div> 
    <div>
        <p dir="ltr">
        The SONA3 Website does not sell any food items listed on the Website directly. We collect payments only as a sales channel for the seller, and therefore we do not guarantee any product listed on the Website or any transaction between the buyer and the seller. We have no control over and do not guarantee the quality, safety or legality of the products advertised, the truth or accuracy of user listings, the ability of sellers to sell products, the ability of buyers to pay for items, or whether a buyer or seller shall actually complete the entire purchase or sale of the product or whether they shall return the product.
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Health and Beauty Products</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        Makeup, Cosmetics and Health products have a strict no refund / exchange policy once opened.
        </p>
        <p dir="ltr">
        SONA3.ae does not bear any responsibility for the health and beauty products sold on the Site and does not guarantee the use, effectiveness or safety of these products as advertised by the seller.        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Limitation of Liability</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        <strong>(A)</strong> The responsibility of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) and its affiliated companies is a responsibility emanating from the supplier of the service or commodity, and he (the supplier of the service or commodity) is fully responsible for it, as the nature of the work of SONA3 is limited to marketing the product or service through its platform only without any responsibility on SONA3 for any claims of any kind arising from or relating to the provision of products or services to the customer or otherwise arising from or connected with this agreement.         </p>
        <p dir="ltr">
        <strong>(B)</strong> In no event shall (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) be liable for any lost profits or savings, loss of data, loss of reputation or loss of goodwill, or indirect, punitive, special, or consequential damages, whether or not those damages are based on tort, warranty, or contract, or even if (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) was informed or was aware of the possibility of such damages occurring.        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Electronic Communications and Official Means of Communication</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        <strong>A-</strong> The user agrees that all agreements, declarations, statements and other communications provided to him electronically substitute their written counterparts, and are a stand-alone argument, in meeting legal and legal needs.
        </p>
        <p dir="ltr">
        <strong>B-</strong>The user agrees to the possibility of communicating with him and informing him of any provisions pertaining to this agreement or relating to dealing with him through the management of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) broadcasting general messages received to all users or to specific users of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) and the same does not represent any violation of privacy.
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Confidentiality</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        The client must maintain technical, commercial and financial information, including any offer, pricing conditions and any other data disclosed to the client by (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C), and it may not disclose this information to any third party or use it for any purpose other than the purpose agreed upon between both parties and in relation to the offer, the agreement, or both of them.
        </p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Waiver and Continuity</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        <strong>(A)</strong> The customer may not assign any agreement or any of his rights or obligations under this agreement without the prior approval of (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C).         
        <p><strong>(B)</strong> (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) may delegate, assign, sell or exchange its obligations and rights, in whole or in part (including amounts receivable) under any agreement to any of Its affiliated companies or any third party without the prior consent of the client, and in the event that such consent is required under (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C), including providing relevant information, editing documents, and making payments to third parties as notified by (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C). 
        </p>
        </p>
    </div>
    <div>
        <p dir="ltr">
        Waiver of a Term: Our waiver of any provision of these Privacy Terms may not be construed as a waiver of any other provisions contained herein (whether similar or different), nor may the waiver of a provision be construed as a permanent waiver thereof, except if we express this explicitly and in writing. 
        </p>
    </div>

    <div>
        <p dir="ltr">
        Survival: All provisions of these Terms of Use, whether express or continuing in nature, shall survive the suspension or expiration of your membership in the Site.
        </p>
    </div>
    <div>
        <p dir="ltr"><strong>Compliance with Laws Anti-Bribery </strong></p>
        <p dir="ltr"><strong>(A) </strong>The client must at all times comply with and take reasonable measures to ensure that its business partners comply with applicable local and international laws and regulations, including anti-bribery and anti-corruption regulations and export regulations. Accordingly, the client must conduct his business with integrity and not be involved in bribery or corruption. </p>
        <p dir="ltr"><strong>(B) </strong>In the event that (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) receives any indications of any breach of obligations, the customer must cooperate with them and provide (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) with all the information required to allow (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) to verify these indicators, and then take appropriate measures in this matter.</p>
    </div>
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Legal Responsibility, Applicable Law, and the Competent Court</h1>
            <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
    <div>
        <p dir="ltr">
        <strong>(A)</strong> All agreements, offers and these Terms are governed by applicable laws without regard to conflict of laws rules. Any legal suit or proceedings arising out of any agreement or offer or out of these terms which are not settled through advice in good faith upon a written notice of thirty (30) days from the date of the dispute by either party shall be filed exclusively with the competent court in United Arab Emirates, provided that in all cases, (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) is permitted to file any lawsuit or take legal action against the customer before any other competent court. 
        </p>
    </div>

    <div>
        <p dir="ltr">
        <strong>(B)</strong>  Nothing in these Terms of Use shall limit or exclude any party's liability for:</p>
        <ul>
            <li><strong>1- </strong> Fraud, including fraud committed by this party.</li>
            <li><strong>2- </strong> Death or personal injury resulting from negligence on the part of this party.</li>
            <li><strong>3- </strong> Any other liability that may not be limited or exempted under applicable law.</li>
        </ul>
    </div>
    <div>
        <p dir="ltr">
        <strong>(C)</strong> In accordance with the aforementioned provisions, we, our parent company, our subsidiaries or affiliates, or our employees, or their directors, members of the boards of directors, employees, agents, suppliers, subcontractors, or licensors, shall not be liable based on a court ruling or an arising claim. In contract, civil law, negligence or breach of legal duties, arising out of or in connection with these terms of use, loss of profits, data or information or any consequential, incidental, indirect or special damages, even if we, our affiliates, directors, members of the boards of directors, agents, employees, licensors, subcontractors or suppliers have been advised of the possibility of such damages.</p>
    </div>
    <div>
        <p dir="ltr">
        <strong>(D)</strong> In addition, as permitted by applicable law, we - including our parent company, subsidiaries, affiliates, employees, directors, members of the boards of directors, employees, agents, suppliers, subcontractors, or licensors - shall not be legally responsible, and you confirm your agreement here that we shall not be responsible for any damages or losses that may arise from the following, whether directly or indirectly: </p>
        <ul>
            <li><strong>1- </strong> Late delivery of products or failure to deliver part of them if you in person fail to provide us with the information we need within a reasonable time from the date we requested it or if you fail to pay the value.</li>
            <li><strong>2- </strong> Damage that may result from unauthorized repairs to products.</li>
            <li><strong>3- </strong> Loss of any data stored or saved in the repaired or replaced products.</li>
            <li><strong>4- </strong> Your complete reliance on the content or any other information provided by the Site related to the product for which you issued a purchase order.</li>
            <li><strong>5- </strong> Your use or inability to use the product you ordered.</li>
            <li><strong>6- </strong> Delay or disruption of the Website or delay or disruption of our services.</li>
            <li><strong>7- </strong> Viruses or electronic malware resulting from the use of the product you ordered.</li>
            <li><strong>8- </strong> Damage to your device as a result of using the product you ordered.</li>
            <li><strong>9- </strong> Your loss of a job opportunity, your inability to conduct your business, or the like due to our inability to deliver the product you ordered on time.</li>
        </ul>
    </div>
    <div>
        <p dir="ltr">
        <strong>(E)</strong> According to Clause (B), if it turns out that Clauses (C) and (D) are not applied or are not enforceable for any reason, then the full legal liability of us, our parent company, subsidiaries, affiliates, or our workers, or their managers, members of the boards of directors, employees, agents, suppliers, subcontractors, or licensors, towards you, whether based on a court ruling or a claim arising from the contract or due to negligence or breach of legal duties arising from or relating to these terms of use, within the following limits: an amount of three hundred (300) AED.</p>
    </div>
    <div>
        <p dir="ltr">
        <strong>(F)</strong> You hereby agree to indemnify and hold us, our parent company, subsidiaries, affiliates, or their respective directors, members of the boards of directors, officers, employees, agents, suppliers, subcontractors, or licensors, harmless from any loss, harm, damage, or expenses (including legal and attorneys' fees) arising out of or in connection with:  </p>
        <ul>
            <li><strong>1- </strong> Any claims or requests submitted by any third party arising from your use of the Site and our services.</li>
            <li><strong>2- </strong> Your violation of any of the terms and conditions of this agreement, including, but not limited to, any guarantees, representations or pledges.</li>
            <li><strong>3- </strong>  Or any violation of applicable laws.</li>
        </ul>
    </div>
      
        <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="General Provisions" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>General Provisions</h1>
            <img className={style.s_line} src={s_line} alt="General Provisions" />
        </div>
        
    <div>
        <p dir="ltr">
            <strong style={{fontFamily:'JosefinSans'}}>Applicable Law: These Terms of Use and any non-contractual rights and obligations arising from or related to these Terms of Use are subject to the laws applicable in the Emirate of Abu Dhabi and shall be construed in accordance with these laws.</strong>
        </p>
    </div>

    <div className={style.header} >
            <img className={style.s_line} src={s_line} alt="Settlement of Disputes" />
                <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Settlement of Disputes</h1>
            <img className={style.s_line} src={s_line} alt="Settlement of Disputes" />
    </div>
    <div>
        <p>- If you are not satisfied with any of the products that you purchased through the site, you can contact us via e-mail, or through our social networking sites, or direct chat on the site, or call our call center at phone number <a href="tel:+971600505006" style={{color: "inherit", textDecoration: "none", fontFamily:"sans-serif"}}>+971 60 050 5006</a> (in the United Arab Emirates).</p>
        <p>- If you cannot reach a solution within forty-five (45) days from notifying the seller of your problem, you can resort to the Abu Dhabi Court of First Instance to hear the dispute.</p>
    </div>
    <div>
        <p dir="ltr">
            <strong style={{fontFamily:'JosefinSans'}}>Rights of Third Parties:</strong> <br></br>
            No party other than the parties to this agreement has the right to enforce any of its provisions.
        </p>
    </div>

    <div>
        <p dir="ltr">
            <strong style={{fontFamily:'JosefinSans'}}>Relationship of the Parties:</strong> 
            None of what is mentioned in the content of these terms of use may be interpreted or considered, whether by its parties or by any third party, as a partnership or joint company between the parties to the agreement, as it is understood that the parties to the agreement entered into a contractual relationship to perform the service of each of them independently of the other. 
        </p>
    </div>

    <div>
        <p dir="ltr">
            <strong style={{fontFamily:'JosefinSans'}}>Additional Assurances:</strong> 
            The Parties agree to do, execute or arrange to do and perform every required act, document or thing reasonably within each of its powers to implement and enforce these Terms of Use to its fullest extent, including but not limited to assisting each other in complying with applicable law.         </p>
    </div>

    <div>
        <p dir="ltr">
            <strong style={{fontFamily:'JosefinSans'}}> Assignment:</strong> 
            These Terms of Use are binding to ensure the interest of its parties, their successors and permitted assigns, and you agree not to assign or transfer the validity of these terms or any of the rights or obligations that belong to you under these terms of use, whether directly or indirectly, without obtaining initial written approval from by us. Provided that we do not refrain from issuing approval without giving a reasonable reason.         </p>
    </div>

    <div>
        <p dir="ltr">
            <strong style={{fontFamily:'JosefinSans'}}>Amendments and Changes:</strong>
            These Terms of Use cannot be varied, changed, amended or supplemented in any way by you alone, and we reserve the right to amend, vary and supplement these Terms of Use at any time and from time to time. We shall also post the current version of the Terms of Use on the Site, and each change shall be effective immediately upon posting on the Site or on the date we designate as the effective date (as applicable). Your continued use of the Site and our services after making any change constitutes your agreement to abide by the changes that have occurred and to implement what is stated in the terms after they are amended and changed.        </p>
    </div>

    <div>
    <p dir="ltr">
        <strong style={{fontFamily:'JosefinSans'}}>Severability of Clauses:</strong>  
        If any court of competent jurisdiction rules that any of the provisions of these Terms of Use are invalid, illegal, or unenforceable, this Clause shall be immediately canceled from these terms and the rest of the terms and conditions shall remain in force and effect as long as the legal and economic essence of the transactions concluded under its terms remains in place without any adverse impact on its parties.     </p>
    </div>

    <div>
        <p dir="ltr" style={{fontFamily:'JosefinSans'}}>
            <strong >*</strong> 
            The invalidity and unenforceability of any provision of these Terms or the Agreement shall not affect the validity and enforceability of any other provision, all of which shall remain in full force and effect. If invalidity or unenforceability is found, the parties shall endeavor to replace the invalid or unenforceable provisions with a valid provision(s) that most closely matches the intent of the invalidated provisions. The customer must, upon reasonable notice, assist (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) in verifying his compliance with the agreement.
        </p>
    </div>

    </div>
    </>
  )
}
