import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import style from './styles/style.module.css';
import './styles/splide.css';
import './styles/style.css';
import '@splidejs/splide/css';

export default function Slider({ faqData, Type, mergedStyles, value, handleChange }) {
  const {t, i18n } = useTranslation();
  const splideRef = useRef(null);

  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);


  return (
    <div>
      <Splide aria-label="faqs" id="faqs_page_splide"
        ref={splideRef}
        options={{
          start: 0,
          rewind: false,
          width: '100%',
          gap: '0.2rem',
          perPage: 4,
          direction: i18n.language === 'en' ? 'ltr' : 'rtl',
          lazyLoad: 'nearby',
          breakpoints: {
            1360: {
              perPage: 3,
            },
            1170: {
              perPage: 3,
              gap: '1rem',
            },
            700: {
              perPage: 2,
              gap: '1.5rem',
            },
          },
        }}
      >
          {Object.keys(faqData[Type]).map((tab, i) => (
          <SplideSlide key={i}>
               <div className={style.Select} style={mergedStyles}>
            <div
              key={tab}
              style={{
                background: value === tab ? "rgb(136, 5, 13)" : "white",
                color: value === tab ? "white" : "black"
              }}
              className={style.Select_Li}
              onClick={() => handleChange(tab)}
            >
              {t(tab)}
            </div>
        </div>
          </SplideSlide>
          ))}
      </Splide>
    </div>
  );
}
