import React, { useEffect, useRef, useState } from 'react'
import style from './styles/welcome.module.css'
import { CheckCircleOutlined } from '@ant-design/icons'
import { FaArrowLeft } from "react-icons/fa6";
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Header1 from './../../../common/Header1';
import CustomButton from '../../../common/CustomButton'
import { h_line, main, v_line } from '../../../assets';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import './styles/splide.css'
import { SELLER_PORTAL, SHOP_PORTAL } from '../../../env';
export default function Welcome() {
const {t,i18n} = useTranslation()
const splideRef = useRef(null);
useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
    splideInstance.destroy();
    splideInstance.mount();
    }
}, [i18n.language]);const navigate = useNavigate()
const[matches,setMatches] = useState(window.matchMedia("(min-width:600px)").matches)
useEffect(() => {const handler = (e) => setMatches( e.matches ); window.matchMedia("(min-width: 600px)").addListener(handler);},[])
const[matches2,setMatches2] = useState(window.matchMedia("(min-width:690px)").matches)
useEffect(() => {const handler = (e) => setMatches2( e.matches ); window.matchMedia("(min-width: 690px)").addListener(handler);},[])
const data = ["Saves time & money", "Platform for handicrafts", "24/7 Technical Support"]
  return (
    <>
    <div className={style.Section} >
        <img className={style.v_line} src={v_line} />
            <div className={style.center_body} >
                <CustomButton onClick={()=>{navigate(`/be%20a%20partner`)}} id={style.Button} text={"See the beginning of the year offers"} component={<FaArrowLeft style={{transform:i18n.language === 'en' ? 'rotate(180deg)' : 'rotate(0deg)'}}/>}/>
               {matches2 ?
                <Header1 classname={style.h1} text="online platform built with Emirati hands"/>
                :
                <>
                <Header1 classname={style.h1} text="online platform"/>
                <Header1 classname={style.h2} text="built with Emirati hands"/>
                </>
            }
                <Header1 classname={style.h3} text="A home for every maker and creator"/>
                {/* <div className={style.p}>{t("We provide integrated services for all types of events. Our services include platform cleaning and preparation and support services")}</div> */}
                {/* <div className={style.p}>{t("We provide integrated services for all types of events. Our services include cleaning and preparing platforms")}</div> */}
                <div className={style.Buttons_flex}>
                    <CustomButton onClick={()=>{window.open(`${SELLER_PORTAL}?lang=${i18n?.language}` , '_blank')}} id={style.Seller_Btn} text={"Register as seller and join us"}/>
                    <CustomButton onClick={()=>{window.open(SHOP_PORTAL, '_blank')}} id={style.Customer_Btn} text={"Go Shopping"}/>
                </div>
                {matches ? 
                <div className={style.line_data}>
                    {data.map((item,key)=>
                         <div className={style.line_data_li}><CheckCircleOutlined className={style.line_data_icon}/>{t(item)} </div>
                    )}
                </div>
                :
                <div className={style.slider}>
                    <Splide  aria-label="info" id="splide1"
                    ref={splideRef}
                        options={ {
                            type: 'loop', 
                            start:0,
                            autoplay:true,
                            interval: 1500,  // Set autoplay speed to 2 seconds
                            speed: 1000,
                            pagination:false,
                            rewind : true,
                            width  : '100%',
                            gap: '0.2rem',
                            perPage: 1,
                            direction: i18n.language === 'en' ? 'ltr' : 'rtl',
                            lazyLoad: 'nearby',
                            // drag   : 'free',
                            loop   : true,
                        }
                        
                        }
                        >
                            {data.map((item,key)=>
                            <SplideSlide>
                                <div className={style.line_data_li}><CheckCircleOutlined className={style.line_data_icon}/>{t(item)} </div>
                            </SplideSlide>
                            )}
                    </Splide>
                </div>
                }
                <img className={style.main_image} src={main}  />
                <img className={style.image_h_line} src={h_line} />
            </div>
        <img className={style.v_line}  id={style.v_line_mt} src={v_line} />
    </div>
    </>
  )
}
