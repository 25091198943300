import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Collapse } from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { common_seller } from '../../../DummyData/Data';
import CustomButton from './../../../common/CustomButton';
import SplitterHeader from '../../../common/SplitterHeader';
import style from './styles/faqs.module.css'
const { Panel } = Collapse;

export default function Faqs() {
  const{t, i18n} = useTranslation()
  const customExpandIcon = ({ isActive }) => ( <> {isActive ? <MinusOutlined /> : <PlusOutlined />} </> );
  const navigate = useNavigate()
  const [Key,setKey] = useState(null)
  const genExtra = (value, key) => (
    <span style={{color: key === Key ? "rgba(136, 5, 13, 1)" : "rgba(17, 17, 17, 1)", fontWeight: key !== Key ? "400" : "bolder"}}>{value}</span>
  );
  const mergedStyles = { fontFamily: i18n.language === 'en' ? 'JosefinSans' : 'GE_SS_light', ...style, };
  return (
    <div className={style.section}>
      <SplitterHeader text="Common Questions"/>
      <Collapse accordion expandIcon={customExpandIcon}  >
      {common_seller.map((panel,key) => (
        <Panel key={panel.key} header={genExtra(t(panel.header),panel.key)} onClick={()=>{panel.key !== Key ? setKey(panel.key) : setKey(null) }} style={mergedStyles}>
          <p style={mergedStyles}>{t(panel.content)}</p>
        </Panel>
      ))}
    </Collapse>
    <CustomButton onClick={()=>{navigate("/faqs")}} DataAos="zoom-in" id={style.Button} text={"More Questions"}/>
    </div>
  )
}
