import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Collapse } from 'antd'
import { all_customer, all_seller, common_customer, payments_customer, delivery_customer, returns_customer, support_customer, common_seller, payments_seller, delivery_seller, returns_seller, support_seller} from '../../DummyData/Data'
import { h_line } from './../../assets/index'
import useApi from '../../components/Loading/LoadingApi'
import SplitterHeader from '../../common/SplitterHeader'
import Slider from './Slider'
import Hero from '../home/sections/Hero'
import BreadCrumb from './../../components/Layout/BreadCrumb'
import style from './styles/style.module.css'
const { Panel } = Collapse

const faqData = {
  customers:{
    "All": all_customer,
    "Registration": common_customer,
    "Products and shipping": delivery_customer,
    "Payment and subscriptions": payments_customer,
    "Return and exchange": returns_customer,
    "Support and assistance": support_customer
  },
  sellers:{
    "All": all_seller,
    "Registration": common_seller,
    "Products and shipping": delivery_seller,
    "Payment and subscriptions": payments_seller,
    "Return and exchange": returns_seller,
    "Support and assistance": support_seller
  }
}

export default function Faqs() {
  useApi(600)
  const { t, i18n } = useTranslation()
  const [data, setData] = useState(faqData.sellers.Registration)
  const [value, setValue] = useState("All")
  const [key, setKey] = useState(null)
  const [Type, setType] = useState('sellers')


  const handleChange = (value) => {
    setValue(value)
  }

  const customExpandIcon = ({ isActive }) => (
    <>{isActive ? <MinusOutlined /> : <PlusOutlined />}</>
  )

  const genExtra = (value, panelKey) => (
    <span style={{ color: panelKey === key ? "rgb(136, 5, 13)" : "rgba(17, 17, 17, 1)", fontWeight: panelKey !== key ? "400" : "bolder" }}>{value}</span>
  )

  const mergedStyles = { fontFamily: i18n.language === 'en' ? 'JosefinSans' : 'GE_SS_light', ...style }
useEffect(() => {
  setData(faqData[Type][value])
}, [Type, value])

  return (
    <div>
      <img className={style.image_h_line} src={h_line} alt='h_line' />
      <BreadCrumb />
      <SplitterHeader text="Common Questions" />
      <div className={style.p}>{t("All inquiries and suggested questions")}</div>
      <div className={style.buttons}>
          <Button style={mergedStyles} className={ Type === "sellers" ? style.button_active : style.button } onClick={()=>{setType("sellers");}} >{t("Sellers")}</Button>
          <Button style={mergedStyles} className={ Type === "customers" ? style.button_active : style.button } onClick={()=>{setType("customers");}} >{t("Customers")}</Button>
        </div>
      <div className={style.content}>
        <div className={style.slider}>
          <Slider faqData={faqData} Type={Type} mergedStyles={mergedStyles} value={value} handleChange={handleChange}/>
        </div>
        <div className={style.expands}>
          <Collapse accordion expandIcon={customExpandIcon}>
            {data.map((panel) => (
              <Panel
                key={panel.key}
                header={genExtra(t(panel.header), panel.key)}
                onClick={() => setKey(panel.key !== key ? panel.key : null)}
                style={mergedStyles}
              >
                <p style={mergedStyles}>
                  {panel[`content_${i18n.language}`] || t(panel.content)}
                  {panel.link && <a style={{fontFamily:'sans'}} href='https://sona3.ae/'>sona3.ae</a>} </p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
      <Hero color={"rgba(223, 144, 25, 1)"} />
    </div>
  )
}
