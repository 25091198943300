import React from 'react'
import useApi from './../../../components/Loading/LoadingApi'
import style from './style/Pages.module.css'
import { cards } from '../../../DummyData/Data'
import Cards from './../components/cards/Cards';
import Header1 from '../../../common/Header1'
export default function Ecommerce() {
useApi(600)
  return (
    <div className={style.page_container}>
      <div className={style.content}>
        <Header1 classname={style.h} text="E-Commerce"/>
        <Cards data={cards}/>
      </div>
    </div>
  )
}
