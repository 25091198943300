import React from 'react'
import { useTranslation } from 'react-i18next'
import SellWithUsAr from './SellWithUsAr';
import SellWithUsEn from './SellWithUsEn';

export default function SellWithUs() {
const {i18n} = useTranslation()
  return (
    <>
    {i18n.language === "en" ? <SellWithUsEn/> : <SellWithUsAr/>}
    </>
  )
}
