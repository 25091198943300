import React,{useEffect,useRef} from 'react'
import { convertToArabicNumeral } from '../../../utils/functions'
import { useTranslation } from 'react-i18next'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Header3 from './../../../common/Header3';
import { news } from '../../../DummyData/Data'
import { useNavigate } from 'react-router-dom'
import { Spin } from 'antd';
import style from './styles/news.module.css'
import SplitterHeader from '../../../common/SplitterHeader';
import '@splidejs/splide/css';
import './styles/splide.css'

export default function News() {
  const{t, i18n} = useTranslation()
  const navigate = useNavigate()
  const splideRef = useRef(null);
  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);

  return (
    <div className={style.section}>
      <SplitterHeader text="Sona3 News"/>
      <div className={style.cards}>
      <Splide  aria-label="My Favorite Images" id="news_splide"
        ref={splideRef}
        options={ {
            // type: 'loop', 
            // start:0,
            // autoplay:true,
            // interval: 3500,  // Set autoplay speed to 3.5 seconds
            // speed: 500,
            rewind : false,
            width  : '100%',
            pagination:true,
            // padding: 80,
            gap:'4rem',
            perPage: 4,
            direction: i18n.language === 'en' ? 'ltr' : 'rtl',
            // lazyLoad: 'nearby',
           // drag   : 'free',
            focus  : 'start',
            //type:'loop',
            breakpoints: {
              1100: {
                  perPage: 3,
                  gap:'1rem',
                  padding: 0,
                },
                640: {
                  perPage: 2,
                  gap:'1rem',
                  padding: 0,
                },
                500: {
                  perPage: 1,
                  gap:'1rem',
                  padding: 0,
                },
              },
          }
        }
        >
        {news.map((item,i) => (   
                <SplideSlide >
                    <div className={style.card}  key={i} id={i} onClick={()=>{window.location.href = i18n.language === 'en' ? "https://sona3.blog/" : "https://sona3.blog/ar/";}}>
                      {/* <LazyLoadImage  className={style.image_h_line} src={item.image}  effect="blur" placeholder={<Spin style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}/> */}
                      <LazyLoadImage className={style.card_image} src={item.image}  effect="blur" placeholder={<Spin style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />} />
                      <div className={style.cardImage} style={{ backgroundImage: `url(${item.image})` }}></div>
                      <div className={style.cardBlur}></div>
                      <div className={style.card_text_content}>
                        <p className={style.date} >{convertToArabicNumeral(7)} {t("Nov")} {convertToArabicNumeral(2023)}</p>
                        <Header3 classname={style.text} text={item.text}/>
                      </div>
                    </div>
                </SplideSlide>
        ))}
        </Splide>
      </div>
    </div>
  )
}
