import React, { useEffect, useState } from 'react';
import style from './style.module.css';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Spin } from 'antd';
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { Description } from '../../../../utils/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Cards({ data }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 500px)").matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 500px)");
    const handler = (e) => setMatches(e.matches);
    mediaQuery.addListener(handler);

    // Cleanup listener on component unmount
    return () => {
      mediaQuery.removeListener(handler);
    };
  }, []);

  // State to manage the number of items displayed
  const [displayCount, setDisplayCount] = useState(matches ? 8 : 4);

  useEffect(() => {
    setDisplayCount(matches ? 8 : 4);
  }, [matches]);

  // Function to handle loading more items
  const loadMoreItems = () => {
    setDisplayCount(prevCount => prevCount + (matches ? 8 : 4));
  };

  return (
    <div className={style.cards}>
      <Row gutter={[16, 32]}>
        {data.slice(0, displayCount).map((item, key) => (
          <Col
            key={key}
            xs={24} sm={12} md={8} lg={6}
            className="gutter-row"
            style={{ fontFamily: i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light" }}
          >
            <div className={style.card_description}>
              <LazyLoadImage className={style.card_image} src={item.image}  effect="blur" placeholder={<Spin style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}/>
              <div className={style.header}>{t(`${item.header}`)}</div>
              <div className={style.p}>{item[Description(i18n.language)]}</div>
              {/* <div onClick={() => { navigate(`/blog/${"E-Commerce"}/${item.header}?_id=${item._id}`) }} className={style.p_}> */}
              <div onClick={() => { navigate(`/`) }} className={style.p_}>
                {t("Read")}
                {i18n.language === 'ar' ? <IoArrowBackOutline className={style.p_icon} /> : <IoArrowForwardOutline className={style.p_icon} />}
              </div>
            </div>
          </Col>
        ))}
      </Row>
      {displayCount < data.length && (
        <Button
          style={{ justifyContent: "center", fontFamily: i18n.language === 'en' ? 'JosefinSans' : "GE_SS_light" }}
          id={style.LoadMore_Btn}
          onClick={loadMoreItems}
        >
          {t("Load More")}
        </Button>
      )}
    </div>
  );
}
