import React from 'react'
import useApi from '../../components/Loading/LoadingApi'
import Welcome from './sections/Welcome'
import About from './sections/About'
import Services from './sections/Services'
import Pricing from './sections/Pricing'
import News from './sections/News'
import Faqs from './sections/Faqs'
import Reviews from './sections/Reviews'
import Hero from './sections/Hero'
import Categories from './sections/Categories'
import SuggessionForm from './sections/SuggessionForm'
import Partners from './sections/Partners'

export default function Home() {
useApi(600)
  return (
    <div>
        <Welcome/>
        <Categories/>
        <About/>
        <Services/>
        <Pricing/>
        <News/>
        <Faqs/>
        <Reviews/>
        <Hero color={"#88050D"}/>
        <SuggessionForm/>
        <Partners/>
    </div>
  )
}
