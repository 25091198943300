import { useEffect, useState,useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { convertToArabicNumeral, getTokenData, Name } from '../../utils/functions'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { advantages, Packages } from '../../DummyData/Data'
import { arrow_vector, bg, content, content_en, cursor, h_line, map, map_en, pricing_cover, pricing_cover_en, wave_down, wave_up } from '../../assets'
import { useNavigate } from 'react-router-dom';
import { Button, Col, Radio, Row } from 'antd'
import { BsFillBookmarkStarFill } from "react-icons/bs";
import useApi from '../../components/Loading/LoadingApi'
import style from './style.module.css'
import Header3 from '../../common/Header3'
import Header1 from '../../common/Header1'
import SplitterHeader from '../../common/SplitterHeader'
import Hero from '../home/sections/Hero';
import BreadCrumb from '../../components/Layout/BreadCrumb';
import CustomButton from '../../common/CustomButton';
import '@splidejs/splide/css';
import '../home/sections/styles/splide.css'
import './style.css'
import { useAuth } from '../../utils/AuthContext';
import { SELLER_PORTAL } from '../../env';

export default function Pricing() {
useApi(600)
const {t,i18n} = useTranslation()
const navigate = useNavigate()
const[Type, setType] = useState('annually')
const[Sort, setSort] = useState('products')
const { tokenData } = useAuth();
const[matches,setMatches] = useState(window.matchMedia("(min-width: 1200px)").matches)
const[matches_main,setMatchesMain] = useState(window.matchMedia("(min-width: 720px)").matches)
useEffect(() => {const handler = (e) => setMatches( e.matches ); window.matchMedia("(min-width: 1200px)").addListener(handler);},[])
useEffect(() => {const handler2 = (e) => setMatchesMain( e.matches ); window.matchMedia("(min-width: 720px)").addListener(handler2);},[])
const splideRef = useRef(null);
const splideRef_2 = useRef(null);

useEffect(() => {
  const splideInstance = splideRef.current?.splide;
  const splideInstance_2 = splideRef_2.current?.splide;
  if (splideInstance) {
    splideInstance.destroy();
    splideInstance.mount();
  }
  if (splideInstance_2) {
    splideInstance_2.destroy();
    splideInstance_2.mount();
  }
}, [i18n.language]);

useEffect(() => {
  setTimeout(() => {
  scrollToTop();
  }, 1000);
  const scrollToTop = () => { window.scrollTo({ top: 2, behavior: "smooth" }); };
}, []);
console.log(i18n.language ,'i18n.language')

  return (
    <>
    <img className={style.image_h_line} src={h_line} alt='h_line' />
    <BreadCrumb/>
    <div 
    style={{direction:i18n.language==="en"?"ltr":"rtl"}}
    >

        <img src={i18n.language==="en"? pricing_cover_en : pricing_cover} className={style.cover} alt={pricing_cover}/>
        <div className={style.welcome}>
          <img src={wave_down} className={style.wave} alt={wave_down}/>
            <Header1 text="Welcome" classname={style.welcome_header}/>
            {/* <h3 className={style.welcome_h3}>{t("Unleash your creativity while we provide you with endless benefits")}</h3> */}
           <Header3 classname={style.welcome_h3} text="Unleash your creativity while we provide you with endless benefits"/>
            <p className={style.welcome_p}>{t("Welcome to the Sona3 introductory space, where you can discover our pioneering program enhancing both traditional and contemporary crafts for UAE citizens and residents. Sona3 amplifies the message of creative industry pioneers through an inspiring, human-centered, and developmental model")}</p>
          <img src={wave_up} className={style.wave} alt={wave_up}/>
        </div>
        <div>
            <div className={style.paper_header}>
              <Header1 text="Advantages of joining sona3" classname={style.header_text}/>
              <img src={bg} alt={bg} className={style.bg}/>
            </div>
            <div className={style.advantage_cards}>
              <Row gutter={[16, 16]}>
                {advantages.map((item, index) => (
                  <Col
                    key={index}
                    xs={12} // 2 columns on extra small screens
                    sm={12} // 2 columns on small screens
                    md={8} // 3 columns on medium screens
                    lg={8} // 3 columns on large screens
                    xl={8} // 3 columns on extra-large screens
                  >
                    <div className={style.advantage_card}>
                        <img src={item.icon} className={style.card_icon} alt={item.icon}/>
                      {item[Name(i18n.language)]}
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
        </div>

            <div className={style.paper_header}>
              <Header1 text="Registration steps" classname={style.header_text}/>
              <img src={bg} alt={bg} className={style.bg}/>
            </div>
            <img src={i18n.language==="en"? content_en :content} className={style.content} alt={content}/>

        <div className={style.paper_header}>
          <Header1 text="Your journey as a maker on sona3" classname={style.header_text}/>
          <img src={bg} alt={bg} className={style.bg}/>
        </div>
        <img src={i18n.language==="en"? map_en : map} className={style.content} alt={map}/>
        
        <div className={style.packages_container}>
          <img src={wave_down} className={style.wave} alt={wave_down}/>
            <SplitterHeader text="Be a partner"/>
            <div className={style.buttons}>
                <Button className={ Sort === "products" ? style.button_active : style.button } onClick={()=>{setSort("products"); }} >{t("Products")}</Button>
                <Button className={ Sort === "services" ? style.button_active : style.button } onClick={()=>{setSort("services");}} >{t("Services")}</Button>
            </div>
         
            {/* <div className={style.arrow_vector_content_relative}>
                <div>{t("Starting from 1 Jan 2025")}</div>
                <img src={arrow_vector} className={style.arrow_vector_image} alt={arrow_vector}/>
            </div> */}

            <div className={style.buttons_container}>
              <div>
                {/* <Button className={ Type === "monthly" ? style.button_active : style.button } onClick={()=>{setType("monthly"); }} >{t("Monthly")}</Button>
                <Button className={ Type === "annually" ? style.button_active : style.button } onClick={()=>{setType("annually");}} >{t("Annually")}</Button> */}
              <Radio.Group value={Type} onChange={(e) => setType(e.target.value)}>
                <Radio value="monthly" style={{ marginRight: "1rem" }}> {t("Monthly")} </Radio>
                <Radio  value="annually" > {t("Annually")}</Radio>
              </Radio.Group>
              </div>
            </div>

        {/* Start of Cards */}
        {matches && <div className={style.cards}  style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
        {Packages[`${Sort}`].map((items,key)=>{ return (
         items?.name !== "lifetime" &&
            <div className={style.card} style={{border:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "3px solid #88050d" : "solid 1px grey"}}>
              { (tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name && <div className={style.popular}><BsFillBookmarkStarFill className={style.popular_icon}/>{t("Popular")}</div> }
              <h1 className={style.card_h1} style={{color:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : "black"}}>{t(`${items.name === "pro" ? "professional" : items?.name}`)}</h1>
                <div className={style.card_p} style={{color:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : "black"}}>{t(`${items.p}`)}</div>
                <div className={style.pricing_content} >
                  <div className={style.main_price} style={{color:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : "rgba(0, 128, 0, 1)"}}><span style={{fontWeight:'bolder'}}>{convertToArabicNumeral(Type ==='monthly' ? items.price_month : items.price_year)} {t("AED")}</span><span className={style.month}>/{Type ==='monthly' ? t("month") : t("yearly")}</span></div>
                  <div className={style.commisions_content} style={{direction:i18n.language === "en" ? "rtl" : "ltr"}}>
                    <div className={style.commisions_content_li} >
                      <p className={style.commisions_content_li_p} style={{background:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : ""}}>{t("Commisions")}</p>
                      <p style={{color:"#88050d", direction:i18n.language === "en" ? "rtl" : "ltr"}} className={style.commisions_content_li_price}>{t(items?.commisions)}</p>
                    </div>
                    <div className={style.commisions_content_li} >
                      <p className={style.commisions_content_li_p} style={{background:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : ""}}>{t("Fixed payment")}</p>
                      <p style={{color:"rgb(80 59 28)"}} className={style.commisions_content_li_price}>{t(items?.fixedPayment)}</p>
                    </div>
                  </div>
                </div>
                <div className={`${style.features_container}`} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
                  <h2 className={style.h2}>{t("Subscription")}</h2>
                  {items.features.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                  <h2 className={style.h2}>{t("Marketing")}</h2>
                  {items.marketing.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                  <h2 className={style.h2}>{t("Advantages")}</h2>
                  {items.advanced.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                  <h2 className={style.h2}>{t("Payment Methods")}</h2>
                  {items.payments.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                </div>
                <CustomButton style={{background:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : ""}} 
                
                onClick={()=>{
                  window.open(
                    `${SELLER_PORTAL}/register?step=main_info&tier=${items?.name}&tierDuration=${Type !== 'annually' ?'month':'year'}&type=${Sort === 'products' ?'product' :'service'}&lang=${i18n?.language}`,
                     '_blank')
                  }} 
                   id={style.subscribe_btn} text={"Get it now"}/>
            </div>
          )}
            )}
          </div>}
          {/* End of Cards */}

      {!matches &&
      <div className={style.splide_container}>
          <Splide  aria-label="My Favorite Images" id="pricing_page_splide"
          ref={splideRef}
          style={{ overflow: 'hidden' }} 
          options={ {
          start:0,
          rewind: false,
          width: '100%',
          gap: '0.5rem',
          arrows:false,
          perPage: 3,
          focus  : 'center',
          direction: i18n.language === 'en' ? 'ltr' : 'rtl',
          //lazyLoad: 'nearby',
          //drag   : 'free',
          //type:'loop',
          breakpoints: {
            1150: { perPage: 2, arrows:true, padding:'10px', gap: '0.2rem', },
            1100: { perPage: 2, arrows:true, padding:'10px'  },
            780: { perPage: 1, arrows:true, padding:'100px', gap:'3rem' },
            650: { perPage: 1, arrows:true, padding:'30px', gap:'3rem' },
            500: { perPage: 1, arrows:true, padding:'0px', gap:'2rem' },
            },
        }
          }
          >
          {Packages[`${Sort}`].map((items,key)=>{ return(
          items?.name !== "lifetime" &&
              <SplideSlide >
            <div className={style.card} style={{border:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "3px solid #88050d" : "solid 1px grey"}}>
            { (tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name && <div className={style.popular}><BsFillBookmarkStarFill className={style.popular_icon}/>{t("Popular")}</div> }
              <h1 className={style.card_h1} style={{color:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : "black"}}>{t(`${items.name === "pro" ? "professional" : items?.name}`)}</h1>
              <div className={style.card_p} style={{color:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : "black"}}>{t(`${items.p}`)}</div>
              <div className={style.pricing_content}>
              <div className={style.main_price} style={{color:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : "rgba(0, 128, 0, 1)"}}><span style={{fontWeight:'bolder'}}>{convertToArabicNumeral(Type ==='monthly' ? items.price_month : items.price_year)} {t("AED")}</span><span className={style.month}>/{Type ==='monthly' ? t("month") : t("yearly")}</span></div>
              <div className={style.commisions_content} style={{direction:i18n.language === "en" ? "rtl" : "ltr"}}>
                    <div className={style.commisions_content_li}>
                      <p className={style.commisions_content_li_p} style={{background:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : ""}}>{t("Commisions")}</p>
                      <p style={{color:"#88050d"}} className={style.commisions_content_li_price}>{t(items?.commisions)}</p>
                    </div>
                    <div className={style.commisions_content_li}>
                    <p className={style.commisions_content_li_p} style={{background:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : ""}}>{t("Fixed payment")}</p>
                    <p style={{color:"rgb(80 59 28)"}} className={style.commisions_content_li_price}>{t(items?.fixedPayment)}</p>
                    </div>
                  </div>
                </div>
                <div className={`${style.features_container_splide}`} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
                  <h2 className={style.h2}>{t("Subscription")}</h2>
                  {items.features.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                  <h2 className={style.h2}>{t("Marketing")}</h2>
                  {items.marketing.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                  <h2 className={style.h2}>{t("Advanced")}</h2>
                  {items.advanced.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                  <h2 className={style.h2}>{t("Payment")}</h2>
                  {items.payments.map((item,index)=>
                  <>
                    <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                  </>
                  )}
                </div>
                <CustomButton style={{background:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "#88050d" : ""}} 
              
                onClick={()=>{
                  window.open(
                    `${SELLER_PORTAL}/register?step=main_info&tier=${items?.name}&tierDuration=${Type !== 'annually' ?'month':'year'}&type=${Sort === 'products' ?'product' :'service'}&lang=${i18n?.language}`,
                     '_blank')
                  }} 
                 id={style.subscribe_btn} text={"Get it now"}/>
            </div>
              </SplideSlide>
            )}
          )}
            </Splide>
            </div>
            }
            {/*---------------------- Life Plan ------------------ */}
             {(tokenData && tokenData?.isLifeTimePlanOn) && 
          <>
            <div className={style.flex_cards}>
                  {Packages[`${Sort}`].map((items,key)=>{ return(
                  items?.name === "lifetime" &&
                    // <SplideSlide >
                     <div style={{display:"block", position:'relative', direction:i18n.language === "en" ? "ltr" : "rtl"}} >
                        <div className={style.card} id={items?.name === "lifetime" ? style.Best : ""} style={{border:(tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name ? "3px solid #88050d" : ""}}>
                        { (tokenData && tokenData?.[Sort=== "products" ? "mostPopularPlanInProducts" : "mostPopularPlanInServices" ]) === items.name && <div className={style.popular}><BsFillBookmarkStarFill className={style.popular_icon}/>{t("Popular")}</div> }
                            <h1 className={style.card_h1} style={{color:items?.name === "lifetime" ? "#88050d" : "green"}}>{t(`${items.name}`)}</h1>
                            <div className={style.card_p}>{t(`${items.p}`)}</div>
                            <div className={style.pricing_content} >
                              <div className={style.main_price}><span style={{fontWeight:'bolder',color:items?.name === "lifetime" ? "#88050d" : "green"}}>{convertToArabicNumeral(items.price_year)} {t("AED")}</span><span style={{fontWeight:'bolder',color:items?.name === "lifetime" ? "#88050d" : "green"}} className={style.month}>/{items?.name === "lifetime" ? t("lifetime") : t("yearly")}</span></div>
                            <div className={style.commisions_content} style={{direction:i18n.language === "en" ? "rtl" : "ltr"}}>
                              <div className={style.commisions_content_li}>
                                <p className={style.commisions_content_li_p} style={{direction:"ltr"}}>{t("Commisions")}</p>
                                <p style={{color:"#88050d"}} className={style.commisions_content_li_price}>{t(items?.commisions)}</p>
                              </div>
                              <div className={style.commisions_content_li}>
                                <p className={style.commisions_content_li_p}>{t("Fixed payment")}</p>
                                <p style={{color:"rgb(80 59 28)"}} className={style.commisions_content_li_price}>{t(items?.fixedPayment)}</p>
                              </div>
                            </div>
                            </div>
                            <div className={`${style.features_container}`}>
                              <h2 className={style.h2}>{t("Subscription")}</h2>
                              {items.features.map((item,index)=>
                              <>
                                <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                              </>
                              )}
                              <h2 className={style.h2}>{t("Marketing")}</h2>
                              {items.marketing.map((item,index)=>
                              <>
                                <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                              </>
                              )}
                              <h2 className={style.h2}>{t("Advantages")}</h2>
                              {items.advanced.map((item,index)=>
                              <>
                                <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                              </>
                              )}
                              <h2 className={style.h2}>{t("Payments")}</h2>
                              {items.payments.map((item,index)=>
                              <>
                                <div className={style.feature_li} >{item.status ? <CheckOutlined style={{color:"green"}}/> : <CloseOutlined style={{color:"#88050d"}}/> }{t(`${item.name}`)}</div>
                              </>
                              )}
                            </div>
                            <CustomButton style={{background:items?.name === "lifetime" ? "#88050d" : "green"}} 
                             onClick={()=>{
                              window.open(
                                `${SELLER_PORTAL}/register?step=main_info&tier=${items?.name}&tierDuration=${Type !== 'annually' ?'month':'year'}&type=${Sort === 'products' ?'product' :'service'}&lang=${i18n?.language}`,
                                 '_blank')
                              }}
                            id={style.subscribe_btn} text={"Get it now"}/>
                        </div>
                        
                    </div>
                  )}
                )}
              </div>

            
          </>
          }
            <div className={style.paper_header} >
            <Header1  onClick={()=>{window.open(`${SELLER_PORTAL}/?lang${i18n?.language}`, '_blank')}} text="Register Now" style={{fontSize:"1.7rem" , cursor:'pointer'}} classname={style.header_text}/>
              <img src={bg} alt={bg} className={style.bg}/>
            </div>
            <img src={wave_up} className={style.wave} alt={wave_up}/>
        </div>
        <Hero color={"rgba(223, 144, 25, 1)"}/>
    </div>
    </>
  )
}
