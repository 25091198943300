import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
// import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import Data from '../../../DummyData/Data';
import Header1 from './../../../common/Header1';
import SplitterHeader from '../../../common/SplitterHeader';
import style from './styles/services.module.css'

export default function Services() {
  const{sellerData, customerData} = Data()
  const[serviceType, setServiceType] = useState('sellers')
  const {t,i18n} = useTranslation()
  const navigate = useNavigate()
  const[data, setData] = useState(sellerData)
  const mergedStyles = { fontFamily: i18n.language === 'en' ? 'GE_SS_bold' : 'GE_SS_light', ...style, };
  return (
    <div className={style.section}>
      <SplitterHeader text="Our Services"/>
      {/* <Header3 classname={style.p} dataAos={"fade-up"} text="The makers of the application make your bridge to the world of creativity and craftsmanship in the Emirates"/> */}
      <div className={style.buttons}>
        <Button style={mergedStyles} className={ serviceType === "sellers" ? style.button_active : style.button } onClick={()=>{setServiceType("sellers"); setData(sellerData)}} >{t("Sellers")}</Button>
        <Button style={mergedStyles} className={ serviceType === "customers" ? style.button_active : style.button } onClick={()=>{setServiceType("customers");setData(customerData)}} >{t("Customers")}</Button>
      </div>
      <div className={style.cards}>
      {data.map((item, key) => (
          <div className={style.card} key={key}  data-aos-duration="1500">
            <img className={style.card_icon} src={item.img} alt="bag" data-aos="zoom-in"/>
            <Header1 classname={style.card_h1} text={item.header}/>
            <div className={style.card_p}>{t(`${item.p}`)}</div>
            {/* <Button style={{boxShadow:"none"}} onClick={()=>navigate(`/services`)} className={style.Card_Btn}><span>{t('Learn More')}</span><FaArrowLeft style={{ transform: i18n.language === 'en' ? 'rotate(180deg)' : 'rotate(0deg)', marginTop:'4px' }} /></Button> */}
          </div>
        ))}
      </div>
    </div>
  )
}
