import React from 'react'
import { useTranslation } from 'react-i18next'
import PrivacyPolicyEn from './PrivacyPolicyEn';
import PrivacyPolicyAr from './PrivacyPolicyAr';

export default function PrivacyPolicy() {
const {t, i18n} = useTranslation()

  return (
    <>
    {i18n.language === "en" ? <PrivacyPolicyEn/> : <PrivacyPolicyAr/>}
    </>
  )
}
