import { about_alert, about_bottles, about_browse, about_compare, about_cs, about_eye, about_globals, about_gps, about_lang, about_line, about_lock, about_notes, about_payment, about_pointer, about_shipping, about_stas, about_target, about_wallet, bg, frame, glamour, grid, h_line, wave_down, wave_up } from '../../assets';
import { useTranslation } from 'react-i18next';
import useApi from '../../components/Loading/LoadingApi'
import Hero from '../home/sections/Hero';
import BreadCrumb from '../../components/Layout/BreadCrumb';
import Header1 from '../../common/Header1'
import style from './style.module.css'
import { Col, Image, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import useGetData from '../../api/useGetData';
import { Name } from '../../utils/functions';
import SplitterHeader from '../../common/SplitterHeader';
import { useEffect, useState } from 'react';

export default function About() {
 useApi(600)
 const navigate = useNavigate()
 const{t,i18n} = useTranslation()
 const { data, loading } = useGetData({ route: 'customer/categories/list', params: { isSubCategory: false, type: "shop", isActive: true } });
 const[matches,setMatches] = useState(window.matchMedia("(min-width: 1000px)").matches)
 useEffect(() => {const handler = (e) => setMatches( e.matches ); window.matchMedia("(min-width: 1000px)").addListener(handler);},[])
 const handleUrl = (id) => {
  navigate(`/categories?id=${id}`);
};
  return (
    <div>
        <img className={style.image_h_line} src={h_line} alt='h_line'/>
        <BreadCrumb/>
        <SplitterHeader text="About Sona3"/>
        <div className={style.paper_header}>
              <Header1 text="Sona3 Story" classname={style.header_text}/>
              <img src={bg} alt={bg} className={style.bg}/>
        </div>
        {/* ------------------ C O N T E N T  (1) ----------------------*/}
        {matches ?
        <div className={style.content_1} style={{direction:i18n.language === "en" ? "rtl" : "ltr"}}>
          <div className={style.cards}>
            <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
              <h3 className={style.card_header}>{t("After awhile...")}</h3>
              <p className={style.card_p}> {t("The methods of selling have completely changed, and most of our attention and reliance has become on modern markets known as industries whose production depends on factories And machines, in contrast, we are less interested in manual products, which embody creativity, excellence, and precision in the quality of their manufacture.")}</p>
            </div>
            <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl", top:'-40px'}}>
              <h3 className={style.card_header}>{t("With Sona3 application...")}</h3>
              <p className={style.card_p}> {t("The fragrance of the beautiful past mixed with the present technological development in the embodiment of an electronic platform that combined traditional values ​​with technical progress in an authentic Emirati character that supports creative hands and encourages them to continue to highlight individuals’ talents and increase their living income, and every manufacturer can experience his experience in displaying his products that he is passionate about making and keen on accuracy. Their quality, handicrafts represent part of our cultural heritage that we must preserve and pass on to future generations.")}</p>
            </div>
          </div>

          <img src={about_line} alt={about_line} className={style.split_img} style={{transform:i18n.language==="en" ? "scaleX(-1)" :"scaleX(1)"}}/>

          <div className={style.cards}>
            <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl", top:"-130px"}}>
              <h3 className={style.card_header}>{t("In the past")}</h3>
              <p className={style.card_p}> {t("With simple tools, handicrafts were transformed from hobbies into professions characterized by beauty and mastery, and reflecting the history and identity of the societies in which they originated. Its people used to walk long distances, going to markets and commercial areas to display their products. At that time, allegiance was the method of selling between them, and face-to-face communication was used to display their crafts and products, and the sale was made. By exchanging hands, shaking hands, and offering words of appreciation, and with the development of time, coins appeared, and these crafts became a source of livelihood for people to live on.")}</p>
            </div>
            <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl", top:'-100px'}}>
              <h3 className={style.card_header}>{t("Nowadays...")}</h3>
              <p className={style.card_p}> {t("The methods of selling have completely changed, and we have entered the era of technological innovation. We can now buy and sell products through mobile phone applications. This has shortened trips and distances, shortened procedures, and granted facilities. Now sellers can display their products in a visual and attractive way on electronic selling platforms, and it is easy for buyers to browse and choose what suits them comfortably.")}</p>
            </div>
            <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl", top:'70px'}}>
              <h3 className={style.card_header}>{t("And today…")}</h3>
              <p className={style.card_p}> {t("SONA3 has become an interactive platform for creatives and makers who love their crafts to sell their products in an easy and unique way. You can now give your loved one a piece made by creative hands and make him lucky with an exclusive gift made especially for him.")}</p>
            </div>
          </div>
        </div>
        :
        <div className={style.content_1} style={{direction:i18n.language === "en" ? "rtl" : "ltr"}}>
     


        <div className={style.cards}>
          <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
            <h3 className={style.card_header}>{t("In the past")}</h3>
            <p className={style.card_p}> {t("With simple tools, handicrafts were transformed from hobbies into professions characterized by beauty and mastery, and reflecting the history and identity of the societies in which they originated. Its people used to walk long distances, going to markets and commercial areas to display their products. At that time, allegiance was the method of selling between them, and face-to-face communication was used to display their crafts and products, and the sale was made. By exchanging hands, shaking hands, and offering words of appreciation, and with the development of time, coins appeared, and these crafts became a source of livelihood for people to live on.")}</p>
          </div>
          <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
            <h3 className={style.card_header}>{t("After awhile...")}</h3>
            <p className={style.card_p}> {t("The methods of selling have completely changed, and most of our attention and reliance has become on modern markets known as industries whose production depends on factories And machines, in contrast, we are less interested in manual products, which embody creativity, excellence, and precision in the quality of their manufacture.")}</p>
          </div>
         
          <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
            <h3 className={style.card_header}>{t("Nowadays...")}</h3>
            <p className={style.card_p}> {t("The methods of selling have completely changed, and we have entered the era of technological innovation. We can now buy and sell products through mobile phone applications. This has shortened trips and distances, shortened procedures, and granted facilities. Now sellers can display their products in a visual and attractive way on electronic selling platforms, and it is easy for buyers to browse and choose what suits them comfortably.")}</p>
          </div>

         
        </div>
        <div className={style.cards}>
          <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
            <h3 className={style.card_header}>{t("With Sona3 application...")}</h3>
            <p className={style.card_p}> {t("The fragrance of the beautiful past mixed with the present technological development in the embodiment of an electronic platform that combined traditional values ​​with technical progress in an authentic Emirati character that supports creative hands and encourages them to continue to highlight individuals’ talents and increase their living income, and every manufacturer can experience his experience in displaying his products that he is passionate about making and keen on accuracy. Their quality, handicrafts represent part of our cultural heritage that we must preserve and pass on to future generations.")}</p>
          </div>

          <div className={style.card} style={{direction:i18n.language === "en" ? "ltr" : "rtl"}}>
            <h3 className={style.card_header}>{t("And today…")}</h3>
            <p className={style.card_p}> {t("SONA3 has become an interactive platform for creatives and makers who love their crafts to sell their products in an easy and unique way. You can now give your loved one a piece made by creative hands and make him lucky with an exclusive gift made especially for him.")}</p>
          </div>
        </div>
      </div>
        }


        {/* ------------------ C O N T E N T  (2) ----------------------*/}
          <div className={style.content_2}>
            <img src={wave_down} className={style.wave} alt={wave_down}/>
              <div className={style.paper_header}>
                    <Header1 text="Who Are We" classname={style.header_text}/>
                    <img src={bg} alt={bg} className={style.bg}/>
              </div>
              <p className={style.content_2_p}>{t("SONA3 platform is an Emirati project that aims to support and empower creatives and industrialists in various heritage and contemporary fields. The platform seeks to provide an electronic environment that combines innovation and marketing, which makes it easier for creative people to transform their ideas into marketable products and services. SONA3 aims to strengthen the national industry and support cultural and contemporary identity In the UAE.")}</p>
            <img src={wave_up} className={style.wave} alt={wave_up}/>
          </div>
        {/* ------------------ C O N T E N T  (3) ----------------------*/}
        <div className={style.content_3}>
          <div className={style.flex_card}>
            <div className={style.flex_card_block_side}>
              <img src={about_eye} alt={about_eye} className={style.flex_card_icon}/>
              <div className={style.flex_card_header}>{t("Our vision")}</div>
            </div>
            <div className={style.flex_card_p}>
                {t("“A platform unique in its quality... distinguished by its originality, influential in community development and an incubator for every maker in the Emirates.”")}
            </div>
          </div>

          <div className={style.flex_card_flipped}>
            <div className={style.flex_card_p}>
                {t("“Our mission is to help and empower owners of home projects and handicrafts, highlight the creative hands of people of determination, and be the link between them and the buyer by providing an easy and innovative platform that brings together all makers to display their unique, handmade products.”")}
            </div>
            <div className={style.flex_card_block_side}>
              <img src={about_target} alt={about_target} className={style.flex_card_icon}/>
              <div className={style.flex_card_header}>{t("Our message")}</div>
            </div>
          </div>

          <div className={style.flex_card}>
            <div className={style.flex_card_block_side}>
              <img src={glamour} alt={glamour} className={style.flex_card_icon}/>
              <div className={style.flex_card_header}>{t("Our valuables")}</div>
            </div>
            <div className={style.flex_card_p} style={{textAlign:"start"}}>
               <div> {t("1-Leadership in integrating creative people into one digital community that enhances their skills")}</div>
               <div> {t("2-Professionalism in managing the capabilities of talented people and empowering them commercially")}</div>
               <div> {t("3-Integration in creating a professional system that includes various intimidation mechanisms for crafts")}</div>
               <div> {t("4-Keeping pace and complying with the latest technologies in the e-commerce sector and its management mechanisms")}</div>
               <div> {t("5-Commitment with manufacturers and consumers to standards of operational efficiency and commercial quality")}</div>
               <div> {t("6-Supporting the pillars of national culture by promoting traditional crafts and their creators")}</div>
            </div>
          </div>
        </div>
         {/* ------------------ C O N T E N T  (4) ----------------------*/}
         <div className={style.content_4}>
            <img src={wave_down} className={style.wave} alt={wave_down}/>
            <div className={style.paper_header}>
                  <Header1 text="Objectives" classname={style.header_text}/>
                  <img src={bg} alt={bg} className={style.bg}/>
            </div>
            <div className={style.content_4_flex}>
                <div className={style.cards}>
                  <div className={style.card}>
                    <p className={style.card_p}> {t("Supporting the lives of families and individuals and enabling them to benefit. With their skills in collecting profitable returns that support their lives")}</p>
                  </div>
                  <div className={style.card}>
                    <p className={style.card_p}> {t("Strengthening the national economy by opening modern production and marketing horizons to support national trade revenues")}</p>
                  </div>
                </div>

              <img src={grid} alt={grid} className={style.grid_img} style={{transform:i18n.language==="en" ? "scaleX(1)":"scaleX(-1)"}}/>

              <div className={style.cards}>
                <div className={style.card}>
                  <p className={style.card_p}> {t("Promoting craft production in the UAE and supporting it with contemporary promotional and marketing techniques")}</p>
                </div>
                <div className={style.card}>
                  <p className={style.card_p}> {t("Creating a digital reflection of the Emirati national heritage and rooting the national culture with the latest modern methods")}</p>
                </div>
                <div className={style.card}>
                  <p className={style.card_p}> {t("Building a digital base that includes craftsmen from multiple cultures, thus enhancing the UAE’s embrace of creative people as a global community")}</p>
                </div>
              </div>
              </div>
              <img src={wave_up} className={style.wave} alt={wave_up}/>
          </div>
         {/* ------------------ C O N T E N T  (5) ----------------------*/}
         <div className={style.content_5}>
            <div className={style.paper_header}>
              <Header1 text="Our services for makers" classname={style.header_text} />
              <img src={bg} alt={bg} className={style.bg}/>
            </div>
          <div className={style.centerlize}>
            <Row gutter={[65, 65]} className={style.content_5_cards}>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_payment} alt="Payment processing" className={style.icon} />
                <div className={style.card_under_p}>{t("Payment processing")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_bottles} alt="Product management" className={style.icon} />
                <div className={style.card_under_p}>{t("Product management")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_globals} alt="Create and customize online stores" className={style.icon} />
                <div className={style.card_under_p}>{t("Create and customize online stores")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_stas} alt="Analytics and reports" className={style.icon} />
                <div className={style.card_under_p}>{t("Analytics and reports")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_alert} alt="Marketing and promotion" className={style.icon} />
                <div className={style.card_under_p}>{t("Marketing and promotion")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_notes} alt="Order and shipping management" className={style.icon} />
                <div className={style.card_under_p}>{t("Order and shipping management")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_lang} alt="Multi-language and currency support" className={style.icon} />
                <div className={style.card_under_p}>{t("Multi-language and currency support")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_lock} alt="Safety and protection" className={style.icon} />
                <div className={style.card_under_p}>{t("Safety and protection")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_cs} alt="Technical support services" className={style.icon} />
                <div className={style.card_under_p}>{t("Technical support services")}</div>
              </Col>
            </Row>
        </div>
        </div>
         {/* ------------------ C O N T E N T  (5 - 6) ----------------------*/}
         <div className={style.content_5}>
         <div className={style.paper_header}>
              <Header1 text="Our services to the public" classname={style.header_text} />
              <img src={bg} alt={bg} className={style.bg}/>
            </div>
          <div className={style.centerlize}>
            <Row gutter={[65, 65]} className={style.content_5_cards}>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_gps} alt="Track orders" className={style.icon} />
                <div>{t("Track orders")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_wallet} alt="Secure payment procedures" className={style.icon} />
                <div >{t("Secure payment procedures")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_browse} alt="Browse products easily" className={style.icon} />
                <div>{t("Browse products easily")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_shipping} alt="Shipping options" className={style.icon} />
                <div>{t("Shipping options")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_lock} alt="Security and privacy" className={style.icon} />
                <div>{t("Security and privacy")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_cs} alt="Customers service" className={style.icon} />
                <div>{t("Customers service")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_compare} alt="Compare products" className={style.icon} />
                <div>{t("Compare products")}</div>
              </Col>
              <Col xs={12} md={8} className={style.content_5_card}>
                <img src={about_pointer} alt="One-click purchase" className={style.icon} />
                <div>{t("One-click purchase")}</div>
              </Col>
            </Row>
            </div>
        </div>
          {/* ------------------ C A T E G O R I E S ----------------------*/}
          <div className={style.categories_section}>
            <div className={style.paper_header}>
              <Header1 text="Categories" classname={style.header_text} />
              <img src={bg} alt={bg} className={style.bg}/>
            </div>
            <Row gutter={[25, 25]} className={style.categories_cards}>
              {data && data.map((item, i) => (
                <Col xs={8} md={8} className={style.Card} onClick={() => { handleUrl(item?._id);}}>
                  <div className={style.circle}>
                    <Image className={style.circle_image} src={frame} preview={false} />
                    <div className={style.label}>{item?.[Name(i18n.language)]}</div>
                  </div>
                </Col>
              ))}
            </Row>
        </div>

        <Hero color={"#88050D"}/>
    </div>
  )
}
