import { useRef,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SplitterHeader from '../../../common/SplitterHeader';
import Slider from '../../categories/components/Slider';
import CustomButton from '../../../common/CustomButton';
import style from './styles/categories.module.css';
import '@splidejs/splide/css';
export default function Categories() {
  const {i18n} = useTranslation()
  const splideRef = useRef(null);
  const navigate = useNavigate()
  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);

  return (
    <div className={style.section}>
      <SplitterHeader text="Categories"/>
      <div className={style.slider}>
      <Slider hideSubs={true}/>
        </div>
        <CustomButton onClick={()=>navigate("/categories")} id={style.list_btn} text={"List All Categories"}/>
    </div>
  )
}
