import axios from 'axios';
import { API_URL } from '../../env';
import i18n from '../../i18n/i18n';
const Axios = axios.create({API_URL});
Axios.defaults.baseURL = API_URL;
Axios.defaults.headers['x-app-token'] = 'Sona3-Team';

Axios.interceptors.request.use(async (req) => {
  //don't check the token in login
  let token = localStorage.getItem("sona3_info_token")
  // console.log({token})
  req.headers['accept-language'] = i18n.language 

  if (req.url === 'login') {
    return req;
  }

  if (req.url === 'register') {
    console.log(req, 'req in if');
    console.log('register in if');
    return req;
  }
  //

  // console.log(req , "fullReq")
  if (!req?.url.includes('/list')) {
    const updatedParams = {...req?.params};
    delete updatedParams?.page;
    delete updatedParams?.limit;
    delete updatedParams?.pageNo;
    // delete req pagenation
    req.params = updatedParams;
    // console.log({req} ,req?.url.includes("/list") )
  }

  console.log({req})

  console.log(req, 'req axios');
  // todo : move inside interceptor
  req.headers.Authorization = `Bearer ${token}`;
  ///to handle form data
  if (req.data instanceof FormData) {  req.headers['Content-Type'] = 'multipart/form-data'; }
  ///
  // console.log(req,"req in axois")
  return req;
});

Axios.interceptors.response.use(
  res => {
    console.log('response=============================================', res);

    return res;
  },
  err => {
    console.log(
      'err=============================================',
      {err},
      err?.response?.data?.error,
    );
   
    if (err?.response?.status === 401 || err?.response?.status === 403) {
      // showToastErrorMSG(t('your session has expired'));
      //@ts-ignore
    }else{
      // showToastErrorMSG(err?.response?.data?.error);
    }

    return Promise.reject(err);
  },
);

export default Axios;
