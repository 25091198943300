import React,{useRef,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import style from './styles/reviews.module.css';
import {  person, layla, sarah } from '../../../assets/index';
import SplitterHeader from '../../../common/SplitterHeader';
import '@splidejs/splide/css';
import './styles/splide.css'
import { Typography } from 'antd';
const { Text } = Typography;

export default function Reviews() {
  const {t,i18n} = useTranslation()
  const splideRef = useRef(null);
  useEffect(() => {
    const splideInstance = splideRef.current?.splide;
    if (splideInstance) {
      splideInstance.destroy();
      splideInstance.mount();
    }
  }, [i18n.language]);
  const categories= [
    {name:t('Jaber Al-Qahtani'),name_ar:'جابر القحطاني', comment:"I loved the wonderful selection of products on the Sona3 platform. It was difficult for me to choose just one product. I received the product I ordered after a short time, and its quality was amazing. I will be a permanent customer of this platform.", image:person},
    {name:t('Aswa Elian'),name_ar:'أسوة عليان', comment:"I am very grateful to have Sona3 platform where I found unique, high-quality products. My purchasing experience was smooth and easy, and the order was delivered on time. I recommend this platform to anyone looking for unique handmade products.", image:sarah},
    {name:t('Mawada Salem'),name_ar:'مودة سالم', comment:"I liked the quick interaction with the customer service team. They were helpful and friendly and helped me solve a small problem I encountered during the purchasing process. The product I chose was great and the shopping experience was great overall.", image:layla},
];
  return (
    <div className={style.section}>
      <SplitterHeader text="Our customer's opinions"/>
      <Splide  aria-label="My Favorite Images" id="reviews_splide"
       ref={splideRef}
        options={ {
            start:0,
            rewind : false,
            width  : '100%',
            gap: '1rem',
            perPage: 3,
            direction: i18n.language === 'en' ? 'ltr' : 'rtl',
            lazyLoad: 'nearby',
           // drag   : 'free',
           //focus  : 'center',
            //type:'loop',
            breakpoints: {
                800: {
                  perPage: 1,
                 
                },
              },
          
                
          }
        
        }
        >
        {categories.map((item,i) => (   
                <SplideSlide >
                    <div className={style.Card} key={i} id={i}  >
                    {/* <div className={style.user_info}>
                        <LazyLoadImage  className={style.user_image} style={{transform:i18n.language === 'en' ? 'scaleX(-1)' : 'scaleX(1)'}} src={item.image}  effect="blur" placeholder={<Spin style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}/>
                        <div className={style.username}>{i18n.language === 'en' ? item.name : item.name_ar}</div>
                      </div> */}
                        <div className={style.comment}>
                          <Text style={{fontFamily: i18n.language === 'en' ? 'JosefinSans' : 'GE_SS_light',}} >{t(`${item.comment}`)}</Text>
                        </div>
                        <div className={style.username}>{i18n.language === 'en' ? item.name : item.name_ar}</div>
                    </div>
                </SplideSlide>
        ))}
        </Splide>
    </div>
  )
}
