import React, { createContext, useContext, useState } from 'react';
// Create a context for authentication
const AuthContext = createContext();
// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};

// AuthProvider component that will wrap your app or specific parts of it
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    // Initialize state from localStorage or default to false
    return localStorage.getItem('sona3_info_token');
  });
const [tokenData, setTokenData] = useState({})
  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, tokenData, setTokenData }}>
      {children}
    </AuthContext.Provider>
  );
};
