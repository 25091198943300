import React from 'react'
import BreadCrumb from '../../components/Layout/BreadCrumb'
import SplitterHeader from '../../common/SplitterHeader'
import { useTranslation } from 'react-i18next'
import useApi from '../../components/Loading/LoadingApi'
import Slider from './components/Slider'
import style from './style/style.module.css'
import { h_line } from '../../assets'
export default function Categories() {
useApi(600)
const{t} = useTranslation()
  return (
    <div className={style.container}>
      <img className={style.image_h_line} src={h_line} alt='h_line' />
      <BreadCrumb/>
      <SplitterHeader text="Categories"/>
      <p className={style.p}>{t("We provide you with all of our comprehensive, distinctive and unique categories in Sona3")}</p>
      <Slider/>
    </div>
  )
}
