import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { RxHamburgerMenu } from "react-icons/rx";
import { CloseOutlined, MailOutlined } from '@ant-design/icons';
import style from './styles/drawer.module.css'
import { createRipples } from 'react-ripples'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { GoHome } from "react-icons/go";
import { PiHandshake } from "react-icons/pi";
import { CiDollar } from "react-icons/ci";
import { MdOutlineInfo } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { GoLaw } from "react-icons/go";
import { BsQuestionCircle } from "react-icons/bs";
import { RiShakeHandsLine } from "react-icons/ri";
import { LuLeafyGreen } from "react-icons/lu";
import { IoCallOutline } from "react-icons/io5";
import { CiMail } from "react-icons/ci";

import './styles/drawer.css'
import { sustainabilty_image } from '../../assets';
const MyRipples = createRipples({
    color: '#ff980038',
    during: 1000,
})

const SideDrawer = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const active = (pathname) => location.pathname === pathname ? style.active_tab : style.li;
  const active_nest = (pathname) => location.pathname.startsWith(pathname) ? style.active_tab : style.li;
  const handleClick = (e, link) => {
    if (link) {
      window.location.href = link;
    }
  };
  return (
    <div>
      <Button onClick={showDrawer} className={style.drawer_icon}>
        <RxHamburgerMenu />
      </Button>
      <Drawer
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        headerStyle={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        title={
          <div className={style.header} style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}>
            <h2>{t("Menu ")}</h2>
            <Button icon={<CloseOutlined />} onClick={onClose} />
          </div>
        }
        className='main_drawer'
      >
        <div style={{ direction: i18n.language === "en" ? "ltr" : "rtl" }}>
          <MyRipples className={active("/")} onClick={() => { navigate('/'); onClose(); }}>
            <GoHome className={style.icon} />
            <div className={style.text}>{t("Home")}</div>
          </MyRipples>
          
          <MyRipples className={active_nest("/about")} onClick={() => { navigate('/about'); onClose(); }}>
            <MdOutlineInfo className={style.icon} />
            <div className={style.text}>{t("About")}</div>
          </MyRipples>

          {/* 
          <MyRipples className={active("/services")} onClick={() => { navigate('/services'); onClose(); }}>
            <PiHandshake className={style.icon} />
            <div className={style.text}>{t("Services")}</div>
          </MyRipples> */}

          <MyRipples className={active("/be%20a%20partner")} onClick={() => { navigate('/be%20a%20partner'); onClose(); }}>
            <CiDollar className={style.icon} />
            <div className={style.text}>{t("Be a partner")}</div>
          </MyRipples>

          <MyRipples className={active_nest("/sustainability")} onClick={() => { navigate('/sustainability'); onClose(); }}>
            <LuLeafyGreen className={style.icon} />
            <div className={style.text}>{t("Sustainability")}</div>
          </MyRipples>

       

          <MyRipples className={active_nest("/blog")}  onClick={()=>{window.location.href = i18n?.language === 'en' ?"https://sona3.blog/" :"https://sona3.blog/ar"; onClose();}}>
            <IoNewspaperOutline className={style.icon} />
            <div className={style.text}>{t("Blog")}</div>
          </MyRipples>

          <MyRipples className={active("/support")} onClick={() => { navigate('/support'); onClose(); }}>
            <TfiHeadphoneAlt className={style.icon} />
            <div className={style.text}>{t("Support")}</div>
          </MyRipples>
          
          <MyRipples className={active_nest("/sell-with-us")} onClick={() => { navigate('/sell-with-us'); onClose(); }}>
            <RiShakeHandsLine className={style.icon} />
            <div className={style.text}>{t("Sell With Us")}</div>
          </MyRipples>

        
         
          <MyRipples className={active_nest("/terms-conditions")} onClick={() => { navigate('/terms-conditions'); onClose(); }}>
            <GoLaw className={style.icon} />
            <div className={style.text}>{t("Terms & Conditions")}</div>
          </MyRipples>
          <MyRipples className={active_nest("/privacy-policy")} onClick={() => { navigate('/privacy-policy'); onClose(); }}>
            <MdOutlinePrivacyTip className={style.icon} />
            <div className={style.text}>{t("Privacy & Policy")}</div>
          </MyRipples>
          <MyRipples className={active_nest("/faqs")} onClick={() => { navigate('/faqs'); onClose(); }}>
            <BsQuestionCircle className={style.icon} />
            <div className={style.text}>{t("Common Questions")}</div>
          </MyRipples>
         
          <MyRipples className={style.li_info} onClick={(e) => handleClick(e, 'tel:+971600505006')}>
            <IoCallOutline className={style.icon} style={{ transform: i18n.language === 'ar' ? "scaleX(-1)" : "scaleX(1)" }} />
            <div className={style.text_info}>
              <a href="tel:+971600505006" style={{ color: "inherit", textDecoration: "none", fontFamily: "sans-serif" }}>
                {i18n.language === 'ar' ? <span style={{ fontFamily: "JosefinSans" }}>(٩٧١٦٠٠٥٠٥٠٠٦)+</span> : "+971 60 050 5006"}
              </a>
            </div>
          </MyRipples>
          <MyRipples className={style.li_info} onClick={(e) => handleClick(e, 'mailto:info@sona3.ae')}>
            <CiMail className={style.icon} />
            <div className={style.text_info}>
              <span style={{ fontFamily: "sans-serif" }}>
                <a href="mailto:info@sona3.ae" style={{ color: "inherit", textDecoration: "none" }}>info@sona3.ae</a>
              </span>
            </div>
          </MyRipples>
            {/* <img onClick={()=>{navigate('/sustainability')}} src={sustainabilty_image} className={style.sustainabilty_image} alt="sustainabilty_img"/> */}
        </div>
      </Drawer>
    </div>
  );
};

export default SideDrawer;
               