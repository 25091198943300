import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import style from './style.module.css';
import { animateScroll as scroll } from 'react-scroll'; // Import animateScroll from react-scroll

const GoToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to show or hide the button based on scrolling position
  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener when component mounts
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Function to scroll to the top of the page with smooth scrolling
  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 500, // Duration of the scroll animation
      smooth: true, // Enable smooth scrolling
    });
  };

  return (
    <div className={style.goToTopButton} style={{ display: isVisible ? 'block' : 'none' }}>
      {/* Use a custom button for scrolling to top */}
      <Button type="primary" shape="circle" className={style.icon} icon={<UpOutlined />} size="large" onClick={scrollToTop} />
    </div>
  );
};

export default GoToTopButton;
