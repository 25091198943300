import React, { useEffect, useState } from 'react'
import useApi from '../../components/Loading/LoadingApi'
import BreadCrumb from '../../components/Layout/BreadCrumb'
import SplitterHeader from '../../common/SplitterHeader'
import { corner, corner2, corner3, group_icon, h_line, i_1, i_2, i_3, img_1, img_2, img_3, img_4, img_5, medal_icon, s_line, ss_cover, sustainabilty_logo } from '../../assets'
import { useTranslation } from 'react-i18next'
import { Button } from 'antd'
import { Row, Col, Card } from 'antd';
import style from './style.module.css'

export default function Sustainability() {
useApi(600)
const {t,i18n} = useTranslation()
const[matches,setMatches] = useState(window.matchMedia("(min-width: 800px)").matches)
useEffect(() => {
    setTimeout(() => {
    scrollToTop();
    }, 1000);
    const scrollToTop = () => { window.scrollTo({ top: 2, behavior: "smooth" }); };
  }, []);
useEffect(() => {const handler = (e) => setMatches( e.matches ); window.matchMedia("(min-width: 800px)").addListener(handler);},[])
const mergedStyles = {
    fontFamily: i18n.language === 'en' ? 'JosefinSans' : 'GE_SS_bold',
};
  return (
    <>
        <img className={style.image_h_line} src={h_line} alt='h_line' />
        <BreadCrumb/>
        <div style={{textAlign:"center"}}>
            <img className={style.logo} src={sustainabilty_logo} alt="sustainabilty_logo"/>
        </div>

        <div className={style.container}>
        <SplitterHeader text="let’s make it green"/>

        <div className={style.header_p_content}>
            <p style={{background:"#F5F5EF"}} className={style.header_p}>{t("In accordance with Vision 2050 and the recommendations of His Highness Sheikh Mohammed bin Zayed Al Nahyan, President of the State, may God protect him, to enhance reliance on renewable energy sources and support scientific research programs in the fields of sustainability, Sanaa devotes all its efforts to following an environmentally friendly approach through awareness guidelines and safe practices for using products. And energy sources.")}</p>
            <p style={{background:"#FCF4E8"}} className={style.header_p}>{t("We believe that handicrafts are not only art and creativity, but also a way to achieve sustainability and preserve our planet, and our goal is to provide a platform that brings together artisans who create amazing handmade products with buyers who are looking for quality and beauty with a commitment to sustainability.")}</p>
        </div>
        <div className={style.cards}>
            <div className={style.card}>
                <img className={style.flex_icon} src={i_2} alt="flex_icon"/>
                <div className={style.card_header}>{i18n.language === "en" ? "-50%" : "-٥٠٪"}</div>
                <div className={style.card_p}>{t("Production waste per ton of product")}</div>
            </div>
            <div className={style.card}>
                <img className={style.flex_icon} src={i_1} alt="flex_icon"/>
                <div className={style.card_header}>{i18n.language === "en" ? "100%" : "١٠٠٪"}</div>
                <div className={style.card_p}>{t("Packaging designed for recycling or reuse")}</div>
            </div>
            <div className={style.card}>
                <img className={style.flex_icon} src={i_3} alt="flex_icon"/>
                <div className={style.card_header}>{i18n.language === "en" ? "30%" : "٣٠٪"}</div>
                <div className={style.card_p}>{t("Share of recycled plastic in the packaging of our consumer products")}</div>
            </div>
        </div>
        {!matches && 
            <div className={style.banner_cards_out}>
                <div className={style.banner_card_out}>
                    <img className={style.banner_card_icon} src={group_icon} alt="banner_card"/>
                    <p className={style.banner_card_p_out}><span style={{fontWeight:"bold"}}>{t("We are proud to be part of your journey towards ")}</span>{t("sustainability and thank you for your support. Together, we can build a better future for generations to come.")}</p>
                </div>
                <div className={style.banner_card_out}>
                    <img className={style.banner_card_icon} src={medal_icon} alt="banner_card"/>
                    <p className={style.banner_card_p_out}><span style={{fontWeight:"bold"}}>{t("Handicrafts are not only art and creativity, ")}</span>{t("but also a way to achieve sustainability and preserve our country")}</p>
                </div>
                {/* <div className={style.flex_buttons}>
                    <Button className={`${style.banner_button} ${style.plant_button}`} style={{fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}}>{t("Plant Tree")}</Button>
                    <Button className={`${style.banner_button} ${style.sub_button}`} style={{fontFamily:i18n.language === 'en' ? "GE_SS_bold" : "GE_SS_light"}}>{t("Support scientific research")}</Button>
                </div> */}
            </div>
        }
        <div className={style.descriptions_container}>
            <div className={style.Header} data-aos="flip-up" data-aos-duration="2000">
                <img className={style.s_line} src={s_line} alt={t(`Our strategy to support`)} data-aos="fade-right" data-aos-duration="2000"/>
                <h1 className={style.h1} style={mergedStyles}>{t(`Our strategy to support`)} <span style={{...mergedStyles, color:"#88050d"}}>{" "}{t(`the sustainability of our Emirates`)}</span></h1>
                <img className={style.s_line} src={s_line} alt={t(`Our strategy to support`)} data-aos="fade-left" data-aos-duration="2000"/>
            </div>
           
            <div className={style.hero_container}>
                <div className={style.hero_blocks}>
                    <div className={style.card_hero}>
                        <img src={img_3} alt={t("Spread Awareness")} className={style.image} />
                        <div className={style.overlay}>
                            <h2>{t("Spread Awareness")}</h2>
                            <p className={style.p_img_overlay}>{t("By updating our blog with this information, you are making a friend.")}</p>
                        </div>
                    </div>
                    <div className={style.card_hero}>
                        <img src={img_1} alt={t("Environmentally friendly products")} className={style.image} />
                        <div className={style.overlay}>
                            <h2>{t("Environmentally friendly products")}</h2>
                            <p className={style.p_img_overlay}>{t("Using environmentally friendly packaging techniques and products.")}</p>
                        </div>
                    </div>
                </div>
                <div className={style.card_hero} style={{ height: 'inherit' }}>
                    <img src={img_2} alt={t("Clean climate")} id={style.image} />
                    <div className={style.overlay}>
                        <h2>{t("Clean climate")}</h2>
                        <p className={style.p_img_overlay}>{t("We are keen to reduce carbon emissions in the manufacturing and production stages.")}</p>
                    </div>
                </div>
                <div className={style.hero_blocks}>
                    <div className={style.card_hero}>
                        <img src={img_5} alt={t("Supporting scientific research")} className={style.image} />
                        <div className={style.overlay}>
                            <h2>{t("Supporting scientific research")}</h2>
                            <p className={style.p_img_overlay}>{t("We allocate a future budget to support sustainability research in various fields.")}</p>
                        </div>
                    </div>
                    <div className={style.card_hero}>
                        <img src={img_4} alt={t("Motivate our partners")} className={style.image} />
                        <div className={style.overlay}>
                            <h2>{t("Motivate our partners")}</h2>
                            <p className={style.p_img_overlay}>{t("During registration we provide benefits to all merchants and buyers who support sustainability.")}</p>
                        </div>
                    </div>
                </div>
            </div>


            <div className={style.rotated_cards_container}>
                <div className={style.Header} >
                    <img className={style.s_line} src={s_line} alt={t(`Our strategy to support`)}/>
                    <h1 style={mergedStyles}>{t(`Advantages for `)} <span style={{...mergedStyles, color:"rgba(10, 128, 106, 1)"}}>{t(`artisan sellers`)}</span></h1>
                    <img className={style.s_line} src={s_line} alt={t(`Our strategy to support`)} />
                </div>
                <div className={style.rotated_cards} style={{direction:i18n.language === 'en' ? "ltr" : "ltr"}}>
                    <div className={style.rotated_card} style={{background:"#88050D", transform:"rotate(-10deg)"}}  >
                        <img className={style.corner_image} src={corner} alt="corner" style={{ transform:"rotate(10deg)", margin:"-5.5px"}}/>
                        <h3 className={style.awarenessHeading} >{t("Sustainability label on products")}</h3>
                        <p className={style.rotated_p}>{t("As a craftsman in our store, you will receive a Sustainability Label that highlights your commitment to sustainable production. This label is not just a symbol, it is a testimony to your efforts in using environmentally friendly materials and production techniques that conserve natural resources. The Sustainability Label will enhance the appeal of your products and the confidence of buyers.")}</p>
                    </div>
                    <div className={style.rotated_card} style={{background:"#0A806A"}} >
                        <img className={style.corner_image} src={corner2} alt="corner" style={{ transform:"rotate(0deg)",  margin:"-1.5px", width:'54px', height:'54px'}}/>
                        <h3 className={style.awarenessHeading}>{t("Eco-friendly shipping bags")}</h3>
                        <p className={style.rotated_p}>{t("We understand the importance of every step in the supply chain, so we provide you with shipping bags made from biodegradable and recyclable materials. These bags are not only environmentally friendly, but they also reflect a positive image of your brand and show your commitment towards environmental protection.")}</p>
                    </div>
                    <div className={style.rotated_card} style={{background:"#DF9019", transform:"rotate(10deg)"}}  >
                        <img className={style.corner_image} src={corner3} alt="corner"  style={{ transform:"rotate(-10deg)", margin:"-5px"}}/>
                        <h3 className={style.awarenessHeading}>{t("Sustainability Makers Blog")}</h3>
                        <p className={style.rotated_p}>{t("Our blog presents ideas and experiences about sustainability through our blog, which is a place to exchange knowledge and inspiration between artisans and buyers, in which we talk about best practices and innovations in the field of sustainable crafts, and success stories of artisans who have made a difference.")}</p>
                    </div>
                </div>
            </div>
       
        </div>


      


        <div className={style.banner}>
            <img src={ss_cover} alt="ss_cover" className={style.banner_image}/>
            <div className={style.banner_content}>
               {matches &&  
               <>
                <h3 className={style.banner_h3}>{t("Every purchase you make from our store is a step towards reducing your environmental impact and promoting sustainability.")}</h3>
                <div className={style.banner_cards}>
                    <div className={style.banner_card}>
                        <img className={style.banner_card_icon} src={group_icon} alt="banner_card"/>
                        <p className={style.banner_card_p}><span style={{fontWeight:"bold",color:"white"}}>{t("We are proud to be part of your journey towards ")}</span>{t("sustainability and thank you for your support. Together, we can build a better future for generations to come.")}</p>
                    </div>
                    <div className={style.banner_card}>
                        <img className={style.banner_card_icon} src={medal_icon} alt="banner_card"/>
                        <p className={style.banner_card_p}><span style={{fontWeight:"bold",color:"white"}}>{t("Handicrafts are not only art and creativity, ")}</span>{t("but also a way to achieve sustainability and preserve our country")}</p>
                    </div>
                </div>
                {/* <div className={style.flex_buttons}>
                    <Button className={`${style.banner_button} ${style.plant_button}`}>{t("Plant Tree")}</Button>
                    <Button className={`${style.banner_button} ${style.sub_button}`}>{t("Support scientific research")}</Button>
                </div> */}
                </>
                }
                {!matches && <h3 className={style.banner_h3}>{t("Every purchase you make from our store is a step towards reducing your environmental impact and promoting sustainability.")}</h3>}
               
            </div>
        </div>
        </div>
    </>
  )
}
